(function () {
    // 'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$http', '$scope', '$state', '$location', 'Auth', 'Principal',
        'ProfileService', 'LoginService', '$rootScope','$window','$timeout', 'Notifications', '$uibModal','ContentControl'];

    function NavbarController($http, $scope, $state, $location, Auth, Principal,
                              ProfileService, LoginService, $rootScope,$window,$timeout, Notifications, $uibModal, ContentControl) {

        var vm = this;

        var location = window.location;
        console.log('Location href: ' + location.href + ', abs URL: ' + $location.absUrl() + ', rootScope supplier portal: ' + $rootScope.supplierPortal);

        vm.navBarClass = "";
        // vm.navBarClass = "header-supplier-portal";

        $scope.$watch('$root.count', function() {
            vm.count = $rootScope.count;
        });

        $scope.$watch('$root.supplierPortal', function() {
            console.log('Root supplier portal changed, value: ' + $rootScope.supplierPortal);
            // vm.navBarClass = "header-supplier-portal";
            vm.navBarClass = "";
        });

        vm.isNavbarCollapsed = true;
        // vm.isAuthenticated = Principal.isAuthenticated;

        // console.log(' vm.isAuthenticated :: '+vm.isAuthenticated);

        // vm.isAuthenticated = false;
        vm.fname = '';
        $scope.account = null;
        $scope.isAuthenticated = false;
        $rootScope.showSearchBox = true;
        vm.iamxHelps = ContentControl.getIAMXhelp();

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        $scope.ifUserNotAdmin = function () {
            return !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_ASSN_FINANCE','ROLE_ANALYST']);
        };

        $scope.ifUserAdAdmin = function () {
            return Principal.hasAnyAuthority(['AD_ADMIN']);
        };

        $scope.ifUserIirsAdmin = function () {
            return Principal.hasAnyAuthority(['IIRS_ADMIN']);
        };

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.getCurrentUser = getCurrentUser;

        vm.authenticated = false;
        vm.$state = $state;

        $rootScope.loginPathLMS = function (){
            Principal.identity().then(function (account) {
                $scope.account = account;
                if($scope.account && $scope.account.login !== 'anonymoususer'){
                    $http.get('/api/path-sso').then(function(response){
                        if(response && response.data){
                            $window.location.href = response.data.id;
                        }
                    });
                }else{
                    $window.location.href='https://www.pathlms.com/iamlearning';
                }
            });
        };

        function getNotifications(){
            $timeout(function(){
                Notifications.getNotificationsForSP(onSuccess, onFailure);
            }, 1000);
        };

        getNotifications();

        function onSuccess(results){
            vm.notifications  = results;
            if(vm.notifications)
                $rootScope.count = vm.notifications.length;
        };

        vm.getTotalCountByType = function(acountArrayIdx, types){
            var count = 0;
            for(var i=0; i<types.length; i++){

                var result = _.filter(vm.notifications, { type: types[i]  }) ;
                if(result)
                    count = count + result.length;
            }
            return count;
        };


        function onFailure(){
            console.log('vm.notifications failure ::: '+JSON.stringify(vm.notifications));
        };

        $scope.mxsupport = function(){
        	var modalInstance = $uibModal.open({
        		templateUrl : 'app/entities/support/support-dialog.html',
				controller : 'SupportController',
				controllerAs : 'vm',
				backdrop : 'static',
				size : 'lg',
				resolve : {
					entity : function() {
						return {
							name : null,
							email : null,
							description : null
						};
					}
				}
	        });
        }

        $scope.iamxstatusmap = function(){
            $state.go('iamxstatusmap', null, {reload: true}).then(function () {

            });

            $timeout(function(){
                $window.location.reload();
            },1000);
        }

        $scope.home = function() {
            var params = {};
            $rootScope.supplierPortal = false;
            $state.go('home', params);
        };

        /*
		 * $scope.$on('authenticationSuccess', function() { ////console.log('In
		 * navbar controller ::: authenticationSuccess!!'); vm.authenticated =
		 * true; });
		 */

        function login() {
            collapseNavbar();
            getCurrentUser();
            LoginService.open();
            // $state.go('home');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            vm.fname = "";
            $state.go('home', null, {reload: true});
// $state.reload();
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }


        // ////////////////


        // ///////////////

        var _selected;
        var _searchTerm;
        $scope.selected = undefined;

        $scope.getServiceProviders = function (val) {
            _searchTerm = val;
            return $http.get('api/_search/service-providers', {
                params: {
                    query: '*' + val + '*',
                    sensor: false
                }
            }).then(function (response) {
                return response.data.map(function (item) {
                    return item.companylegalname + ', '
                        + item.serviceproviderAddresses[0].city + ', '
                        + item.serviceproviderAddresses[0].state + ', '
                        + item.serviceproviderAddresses[0].country;
                });
            });
        };

        vm.fname = '';

        function getCurrentUser() {
            return $http.get('api/users/currentuser', {}).then(function (response) {
                var fullNameOfCurrentUser = response.data.firstName + " " + response.data.lastName;
                vm.fname = fullNameOfCurrentUser;
            });
        }


        $scope.ngModelOptionsSelected = function (value) {
            if (arguments.length) {
                _selected = value;
            } else {
                return _selected;
            }
        };


        /**
		 * Location Search
		 *
		 * @param val
		 * @returns {*}
		 */
        $scope.getLocation = function (loc_val) {

            if (loc_val.length < 4)
                return;

            _searchTerm = loc_val;

            return $http.get('api/_search/addresses/location', {
                params: {
                    query: '' + loc_val + '',
                    sensor: false
                }
            }).then(function (response) {
                return response.data.map(function (searchresults) {
                    return searchresults.searchResult;
                });
            });
        };


        $scope.getServices = function (svc_val) {

            if (svc_val.length < 3)
                return;

            _searchTerm = svc_val;

            return $http.get('api/_search/services/service', {
                params: {
                    query: '' + svc_val + '',
                    sensor: false
                }
            }).then(function (response) {
                return response.data.map(function (searchresults) {
                    return searchresults.searchResult;
                });
            });
        };

        $scope.modelOptions = {
            debounce: {
                default: 500,
                blur: 250
            },
            getterSetter: true
        };


        // ////////////////
    }
})();
