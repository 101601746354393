/*eslint-env jquery*/
(function () {
    // 'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ServiceProvidersDialogController', ServiceProvidersDialogController)
        .directive('ngThumb', ['$window', function ($window) {
            var helper = {
                support: !!($window.FileReader && $window.CanvasRenderingContext2D),
                isFile: function (item) {
                    return angular.isObject(item) && item instanceof $window.File;
                },
                isImage: function (file) {
                    var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            };

            return {
                restrict: 'A',
                template: '<canvas/>',
                link: function (scope, element, attributes) {
                    if (!helper.support) return;

                    var params = scope.$eval(attributes.ngThumb);

                    if (!helper.isFile(params.file)) return;
                    if (!helper.isImage(params.file)) return;

                    var canvas = element.find('canvas');
                    var reader = new FileReader();

                    reader.onload = onLoadFile;
                    reader.readAsDataURL(params.file);

                    function onLoadFile(event) {
                        var img = new Image();
                        img.onload = onLoadImage;
                        img.src = event.target.result;
                    }

                    function onLoadImage() {
                        var width = params.width || this.width / this.height * params.height;
                        var height = params.height || this.height / this.width * params.width;
                        canvas.attr({width: width, height: height});
                        canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
                    }
                }
            };
        }]);


    ServiceProvidersDialogController.$inject = ['$timeout', '$scope', '$filter',
        'entity', 'ServiceProviders', 'Lookups', 'Addresses', '$state',  'Account', '$http',
        'Principal', 'FileUploader', '$cookies', 'DateUtils', '$sce', 'SPDataService','$window', 'AuditLog',
        '$stateParams','$rootScope','$q', 'InvoiceHeader','MemberContacts', 'CustomerNotes', 'AdlTracker', '$uibModal'];

    function ServiceProvidersDialogController($timeout, $scope, $filter, entity,
                                              ServiceProviders, Lookups, Addresses, $state,
                                               Account, $http, Principal, FileUploader,
                                              $cookies, DateUtils, $sce, SPDataService, $window, AuditLog,
                                              $stateParams,$rootScope, $q, InvoiceHeader, MemberContacts, CustomerNotes,
                                              AdlTracker, $uibModal) {

        var vm = this;

        $scope.panCardRegex = '/[A-Z]{5}\d{4}[A-Z]{1}/i';
        $rootScope.showSearchBox = true;
        $scope.controllerName = "ServiceProvidersDialogController";
        $scope.authorities = [];

        $http.get('/api/authorities').then(function(res){
            $scope.authorities = res.data;
        });

        vm.hours = DateUtils.getHoursList();

        vm.isIAMMember = function (associations, compliance){

        	if(compliance === 'PCG'){
        	    if (associations) {
                    for (var i = 0; i < associations.length; i++) {
                         var memberAssoc = associations[i];
                         if(memberAssoc.memberAssociation.id === 800 && memberAssoc.memberStatus.id === 1201 ){
                             return false;
                         }
                    }
                }
        		return true;
        	}

         	return false;
        }

        vm.canEditFeaturedServices = function (associations){
            if ($scope.isAdminUser) {
                return true;
            }

            var hasActiveFeaturedServices = function (association) {
                return association.memberAssociation.id === 960 && association.memberStatus.id === 1201;
            };

            return _.some(associations, hasActiveFeaturedServices);
        }

        vm.showFeaturedServices = function () {
            return vm.serviceProviders.mxstatus != null &&
                (vm.serviceProviders.mxstatus.lookupcode === '3' || vm.serviceProviders.mxstatus.lookupcode === '4'
                    || vm.serviceProviders.mxstatus.lookupcode === '5');
        };

        $scope.getProviderContactRoles = function (providerContact, id) {
			$http
                .get('/api/provider-contact-roles/' + id)
                .then(
                    function(response) {
                        $scope.providerContactRoles = response.data.authorities;
                        $scope.hasSignInCredentials[id] = 'NO';
                        $scope.hasAdminRight[id] = 'NO';
                        if ($scope.providerContactRoles)
                            for (var i = 0; i < $scope.providerContactRoles.length; i++) {
                                if ($scope.providerContactRoles[i]) {
                                    var roleName = $scope.providerContactRoles[i].name;
                                    if(roleName === 'ROLE_USER'){
                                        $scope.hasSignInCredentials[id] = 'YES';
                                    }
                                    if(roleName === 'ROLE_SP_ADMIN'){
                                        $scope.hasAdminRight[id] = 'YES';
                                    }
                                }
                            }
                    });
		};

        //
        $scope.canEditContact = function (contactCompanyId){
            return $scope.vm.serviceProviders.id == memberContacts.companyId;
        }

        // date format

        $scope.displayDateFormat = DateUtils.displayDateFormat;
        $scope.navtype = _.isUndefined($stateParams.navtype)?'':_.isUndefined($stateParams.navtype);

        $scope.getFormattedDate = function(date){
           // console.log(' date '+date);
            return DateUtils.convertDateTimeFromServer(date);
        };

        $scope.sendValidationEmail = function(id){
            $http.put('/api/send-validation-email/'+id, function (data) {

            }).success( function(response) {
               // $scope.providerContactRoles = response.data;
            });
        };
        // phone fields

        var addressPhoneFields =
            {
                "phoneNumber": "Phone",
                "faxnumber": "FAX Number",
                "tollfreenumber": "Toll Free Number",
                "afterhoursnumber": "After hours Number",
                "whatsapp": "WhatsApp Number",
            }
            ;

        var contactPhoneFields =
            {
                "officephone": "Office Phone",
                "cellphone": "Cell Phone",
            }
            ;

        $scope.ifUserNotAdmin = function () {
            return !Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        $scope.ifUserNotSPAdmin = function () {
            return !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SP_ADMIN']);
        };

        $scope.ifUserAdminAnalyst = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_ANALYST']);
        };

        $scope.ifUserFinanceAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ASSN_FINANCE']);
        };

        $scope.hasOnlineRates = function (serviceProvider) {
            return serviceProvider.mxstatus.lookupcode === '3' || serviceProvider.mxstatus.lookupcode === '4' || serviceProvider.mxstatus.lookupcode==='5';
        }

        $scope.hasAdlTrackerAccess = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'IIRS_ADMIN', 'RPP_ADMIN']);
        };

        // split phone numbers

        $scope.splitAddressPhoneNumbers = function (serviceproviderAddress) {

            serviceproviderAddress.phoneEditFields = {};
            var countryCode = "";
            $.each
            (
                addressPhoneFields,
                function (addressPhoneField, addressPhoneFieldLabel) {
                    serviceproviderAddress.phoneEditFields[addressPhoneField] =
                    {
                        "label": addressPhoneFieldLabel,
                        "countryCode": "",
                        "localNumber": "",
                    }
                    ;

                    if (serviceproviderAddress[addressPhoneField]) {
                        if (serviceproviderAddress[addressPhoneField].charAt(0) == "+") {
                            var phoneNumberSeparatorPosition = serviceproviderAddress[addressPhoneField].indexOf(" ");
                            if (phoneNumberSeparatorPosition == -1) {
                                serviceproviderAddress.phoneEditFields[addressPhoneField]["countryCode"] = serviceproviderAddress[addressPhoneField];
                                serviceproviderAddress.phoneEditFields[addressPhoneField]["localNumber"] = "";
                                countryCode = serviceproviderAddress[addressPhoneField];

                            } else {
                                serviceproviderAddress.phoneEditFields[addressPhoneField]["countryCode"] = serviceproviderAddress[addressPhoneField].slice(1, phoneNumberSeparatorPosition);
                                serviceproviderAddress.phoneEditFields[addressPhoneField]["localNumber"] = serviceproviderAddress[addressPhoneField].slice(phoneNumberSeparatorPosition + 1);
                                countryCode = serviceproviderAddress[addressPhoneField].slice(1, phoneNumberSeparatorPosition);
                            }
                        } else {
                            serviceproviderAddress.phoneEditFields[addressPhoneField]["countryCode"] = countryCode;
                            serviceproviderAddress.phoneEditFields[addressPhoneField]["localNumber"] = serviceproviderAddress[addressPhoneField];

                        }
                    } else {
                        serviceproviderAddress.phoneEditFields[addressPhoneField]["countryCode"] = countryCode;
                        serviceproviderAddress.phoneEditFields[addressPhoneField]["localNumber"] = "";

                    }
                }
            )
            ;
        };

        // variables

        var isCopy = 'false';// previousState.isCopy;

        vm.serviceProviders = entity;

        $scope.hasSignInCredentials = [];
		$scope.hasAdminRight = [];

        vm.closeCustomerNotesDialog = closeCustomerNotesDialog;
        vm.customerNotesList = vm.serviceProviders.customerNotes;

        // Add a displayNote property to each customer note. To avoid issues with long notes, limit the number of
        // characters displayed in the table. Edit a note shows the complete note.
        _.forEach(vm.customerNotesList, setCustomerNoteDisplayNote);

        vm.customerNotes = {};
        vm.iamTrustedAffirmation = {};
        // vm.iamTrustedAffirmation.today = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss Z');
        vm.iamTrustedAffirmation.today = $filter('date')(new Date(), 'mediumDate');

        console.log('Original affirmation date: ' + vm.serviceProviders.itmcOriginAffirmationDate);
        if (vm.serviceProviders.itmcOriginAffirmationDate != null) {
            vm.iamTrustedAffirmation.affirmationName = vm.serviceProviders.itmcAffirmationName;
            vm.iamTrustedAffirmation.affirmationTitle = vm.serviceProviders.itmcAffirmationTitle;
            vm.iamTrustedAffirmation.submitterName = vm.serviceProviders.itmcSubmitterName;
        }

        SPDataService.setSP(vm.serviceProviders);

        vm.currentYear = new Date().getFullYear();
        $scope.activateLabel = [];

        $scope.activeProviderContactExists = false;

        if(vm.serviceProviders.subscriptionControls && vm.serviceProviders.subscriptionControls.length > 0){
            vm.subscriptionControls = vm.serviceProviders.subscriptionControls;
        } else {
            vm.subscriptionControls = [{}];
        }

        vm.logout = logout;
        function logout() {
            collapseNavbar();
            Auth.logout();
            vm.fname = "";
            $state.go('home', null, {reload: true});
// $state.reload();
        }
        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        vm.expDateError = false;
        vm.validateExpiryDate = function(obj){
            if(obj.certDate!== null && obj.certDate > obj.expDate){
                obj.expDateError = true;
                obj.expDate = '';
            }else{
                obj.expDateError = false;
            }
        };

        vm.certDateError = false;
        vm.validateCertDate = function(obj){
            if(obj.expDate!== null && obj.expDate < obj.certDate){
                obj.certDateError = true;
                obj.certDate = '';
            }else{
                obj.certDateError = false;
            }
        };

//
        vm.endDateError = false;
        vm.validateEndDate = function(obj){
            if(obj.startDate!== null && obj.startDate > obj.endDate){
                obj.endDateError = true;
                obj.endDate = '';
            }else{
                obj.endDateError = false;
            }
        };

        vm.startDateError = false;
        vm.validateStartDate = function(obj){
            if(obj.endDate!== null && obj.endDate < obj.startDate){
                obj.startDateError = true;
                obj.startDate = '';
            }else{
                obj.startDateError = false;
            }
        };

        vm.setDepositBalance = function(){
        	vm.serviceProviders.depositBalance = vm.serviceProviders.depositRequirement;
        };

        if(vm.serviceProviders.advCampaignSetup && vm.serviceProviders.advCampaignSetup.advLocationSetups){
            vm.invoiceLines = vm.serviceProviders.advCampaignSetup.advLocationSetups;
        } else {
        	vm.locationSetups = [{}];
        }

        //
        vm.totalItems = 0;
        vm.currentPage = 1;
        vm.itemsPerPage = 10;
        vm.transition = transition;
        vm.searchContact = searchContact;
        vm.clearContact = clearContact;
        vm.saveAdlTracker = saveAdlTracker;
        vm.clearAdlTracker = clearAdlTracker;
        vm.editAdlTracker = editAdlTracker;
        vm.sort = 'lastname';
        vm.direction = 'asc';
        vm.searchFirstQuery = '';
        vm.searchLastQuery = '';
        vm.serviceproviderContacts = [];
        vm.spAdlTrackers = [];
        vm.adlTracker = {};

        vm.removeContactDialogOpen = {};

        vm.isRemoveContactDialogOpen = function (memberId) {
            return vm.removeContactDialogOpen['' + memberId] != null;
        }

        var getContactsUrl = '/api/provider-contacts/get?page=0&size='+vm.itemsPerPage+'&sort='+vm.sort+'&direction='+vm.direction+'&firstname='+vm.searchFirstQuery+'&lastname='+vm.searchLastQuery;
        getContactsUrl += ('&spid='+ (vm.serviceProviders.id ? vm.serviceProviders.id : 0));
        // $http.get('/api/provider-contacts/get?page=0&size='+vm.itemsPerPage+'&sort='+vm.sort+'&direction='+vm.direction+'&firstname='+vm.searchFirstQuery+'&lastname='+vm.searchLastQuery+'&spid='+vm.serviceProviders.id).then(function(res){
        $http.get(getContactsUrl).then(function(res){
            vm.totalItems = res.data.totalItems;
            vm.serviceproviderContacts = res.data.providerContacts;
            if(vm.serviceproviderContacts){
                console.log("Processing contacts: " + vm.serviceproviderContacts.length + ', account: ' + $scope.account);
                for(var i = 0; i < vm.serviceproviderContacts.length; i++){
                    $scope.getProviderContactRoles(vm.serviceproviderContacts[i], vm.serviceproviderContacts[i].id);
                    console.log('Affirmation: ' + vm.iamTrustedAffirmation + ', account email: ' + $scope.account.email.toLowerCase());
                    if (vm.serviceproviderContacts[i].email && ($scope.account.email.toLowerCase() === vm.serviceproviderContacts[i].email.toLowerCase()) && vm.iamTrustedAffirmation && vm.serviceProviders.itmcOriginAffirmationDate != null) {
                        vm.iamTrustedAffirmation.affirmationTitle = vm.serviceproviderContacts[i].title;
                        console.log('Set affirmation title' + vm.iamTrustedAffirmation.affirmationTitle);
                    }
                }
            }
        });

        var getAdlTrackersUrl = '/api/adl-tracker/sp/' + (vm.serviceProviders.id ? vm.serviceProviders.id : 0);
        $http.get(getAdlTrackersUrl).then(function(res){
            vm.spAdlTrackers = res.data;
            if (vm.spAdlTrackers){
                console.log("Processing ADL trackers: " + vm.spAdlTrackers.length + ', account: ' + $scope.account);
            }
        });

        //
        function clearContact(){
            vm.searchFirstQuery = '';
            vm.searchLastQuery = '';
            searchContact(vm.sort, true);
        }

        function searchContact(sort, order){
            vm.sort = sort;

            if(!order){
                vm.direction = 'desc';
            } else {
                vm.direction = 'asc';
            }
            var page = vm.currentPage - 1;
            $http.get('/api/provider-contacts/get?page='+page+'&size='+vm.itemsPerPage+'&sort='+vm.sort+'&direction='+vm.direction+'&firstname='+vm.searchFirstQuery+'&lastname='+vm.searchLastQuery+'&spid='+vm.serviceProviders.id)
            .then(function(res){
                vm.totalItems = res.data.totalItems;
                vm.serviceproviderContacts = res.data.providerContacts;
                if(vm.serviceproviderContacts){
                    for(var i = 0; i < vm.serviceproviderContacts.length; i++){
                        $scope.getProviderContactRoles(vm.serviceproviderContacts[i],
                                vm.serviceproviderContacts[i].id);
                    }
		        }
            });
            $scope.editForm.$dirty=false;
        }

        function editAdlTracker(id) {
            console.log("Edit ADL tracker, id: " + id);

            var adlTracker = vm.spAdlTrackers.filter(function(item){
              return item.id === id;
            });

            if (adlTracker && adlTracker.length > 0) {
                vm.adlTracker.id = adlTracker[0].id;
                if (adlTracker[0].dateStart) {
                    vm.adlTracker.dateStart = new Date(adlTracker[0].dateStart);
                }
                if (adlTracker[0].dateEnd) {
                    vm.adlTracker.dateEnd = new Date(adlTracker[0].dateEnd);
                }
                vm.adlTracker.complaints = adlTracker[0].complaints;
                vm.adlTracker.unpaidDebt = adlTracker[0].unpaidDebt;
                vm.adlTracker.notesInternal = adlTracker[0].notesInternal;
                vm.adlTracker.notesPublic = adlTracker[0].notesPublic;
            }
        }

        function onSaveAdlTrackerSuccess (result) {
            $scope.saveAdlTrackerResult = "The ADL tracker was successfully saved.";
            $scope.saveAdlTrackerSuccess = true;
            $scope.showSaveAdlTrackerAlert = true;

            refreshAdlTrackers();
            $scope.$emit('mobilityexchangeApp:adlTrackerUpdate', result);
            vm.isSaving = false;
            $scope.editForm.$dirty=false;
        }

        $scope.closeSaveAdlTrackerAlert = function() {
            console.log('Closing save ADL tracker alert');
            $scope.showSaveAdlTrackerAlert = false;
        }

        function onSaveAdlTrackerError () {
            vm.isSaving = false;

            $scope.saveAdlTrackerResult = "Failed to save the ADL tracker.";
            $scope.saveAdlTrackerSuccess = false;
            $scope.showSaveAdlTrackerAlert = true;
        }

        function saveAdlTracker () {
            vm.isSaving = true;

            // Populate service provider id
            // TODO: Remove once backend is updated
            vm.adlTracker.serviceProvider = {};
            vm.adlTracker.serviceProvider.id = vm.serviceProviders.id;

            vm.adlTracker.serviceprovider = {};
            vm.adlTracker.serviceprovider.id = vm.serviceProviders.id;


            if (vm.adlTracker.id) {
                AdlTracker.update(vm.adlTracker, onSaveAdlTrackerSuccess, onSaveAdlTrackerError);
            } else {
                AdlTracker.save(vm.adlTracker, onSaveAdlTrackerSuccess, onSaveAdlTrackerError);
            }
        }

        function clearAdlTracker () {
            // Clear form fields
            vm.adlTracker.id = null;
            vm.adlTracker.dateStart = null;
            vm.adlTracker.dateEnd = null;
            vm.adlTracker.complaints = null;
            vm.adlTracker.unpaidDebt = null;
            vm.adlTracker.notesInternal = '';
            vm.adlTracker.notesPublic = '';
        }

        function refreshAdlTrackers() {
            console.log("Refreshing adl trackers");

            AdlTracker.getAll({
                spId: vm.serviceProviders.id
            }).$promise.then(function success(response) {
                vm.spAdlTrackers = response;
                // Flatten service provider in alerts to be able to save the service provider with latest alerts
                var adlTrackers = response;
                _.forEach(adlTrackers, function (adlTracker) {
                    adlTracker.serviceprovider = adlTracker.serviceprovider.id;
                });
                vm.serviceProviders.adlTrackers = adlTrackers;
            });
            clearAdlTracker();
        }

        function transition() {
            var page = vm.currentPage - 1;
            $http.get('/api/provider-contacts/get?page='+page+'&size='+vm.itemsPerPage+'&sort='+vm.sort+'&direction='+vm.direction+'&firstname='+vm.searchFirstQuery+'&lastname='+vm.searchLastQuery+'&spid='+vm.serviceProviders.id)
            .then(function(res){
                vm.totalItems = res.data.totalItems;
                vm.serviceproviderContacts = res.data.providerContacts;
                if(vm.serviceproviderContacts){
                    for(var i = 0; i < vm.serviceproviderContacts.length; i++){
                        $scope.getProviderContactRoles(vm.serviceproviderContacts[i],
                                vm.serviceproviderContacts[i].id);
                    }
		        }
            });
             $scope.editForm.$dirty=false;
        }

        // split phone numbers
        $.each
        (
            vm.serviceProviders.serviceproviderAddresses,
            function (index, serviceproviderAddress) {
                $scope.splitAddressPhoneNumbers(serviceproviderAddress);

            }
        )
        ;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.status = { rolesopen: true };
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveCRM = saveCRM;
        vm.saveIamtrustedAffirmation = saveIamtrustedAffirmation;

        if (vm.serviceProviders.serviceProvidersId) {
            vm.parentSP = ServiceProviders.get({id: vm.serviceProviders.serviceProvidersId});
        }

        vm.addressTypes = Lookups.getByLookupType({id: 16});
        vm.memberCapabilities = Lookups.getByLookupType({id: 2});
        vm.movingCapabilities = Lookups.getByLookupType({id: 5});
        vm.serviceScopes = Lookups.getByLookupType({id: 87});
        vm.relocationCapabilities = Lookups.getByLookupType({id: 6});
        vm.industryPartnerCapabilities = Lookups.getByLookupType({id: 7});
        vm.industrySupplierCapabilities = Lookups.getByLookupType({id: 8});

        vm.associations = Lookups.getByLookupType({id: 9});
        vm.quality = Lookups.getByLookupType({id: 11});
        vm.compliance = Lookups.getByLookupType({id: 23});
        vm.usGovtCapabilities = Lookups.getByLookupType({id: 19});
        vm.mxStatus = Lookups.getByLookupType({id: 1});
        vm.countryCodes = Lookups.getByLookupType({id: 50});
        vm.states = Lookups.getByLookupType({id: 51});
        vm.functionalRoles = Lookups.getByLookupType({id: 12});
        vm.languages = Lookups.getByLookupType({id: 53});
       // vm.contactStatus = Lookups.getByLookupType({id: 22});
        vm.memberTypes = Lookups.getByLookupType({id: 21});
        // vm.contactQualifications = Lookups.getByLookupType({id: 33});
        vm.terminationReasons = Lookups.getByLookupType({id: 31});
        vm.memberStatuses = Lookups.getByLookupType({id: 30});
        vm.timeZones = Lookups.getByLookupType({id: 66});
        vm.destinationServices = Lookups.getByLookupType({id: 95});

        vm.deleteAddressTmpl = 'deleteAddressTmpl.html';
        vm.deleteContactTmpl = 'deleteContactTmpl.html';
        vm.removeMemberContactTmpl = 'removeMemberContactTmpl.html'
        vm.mainAddressPhonePrefix = (vm.serviceProviders.serviceproviderAddresses === undefined || vm.serviceProviders.serviceproviderAddresses.length == 0)? 1 : vm.serviceProviders.serviceproviderAddresses[0].phonePrefix;
        vm.currentYear = new Date().getFullYear();
        $scope.selectedAddressId;
        $scope.selectedFunctionalRoleId;

        $scope.account = null;
        $scope.isAuthenticated = false;
        $scope.isAdminUser = false;
        $scope.isAssignedMemberAdminUser = false;
        $scope.isMemberAdminUser = false;
        $scope.isFinanceAdminUser = false;

        $scope.letterlUrl = 'cert_pop.html';
        vm.taxId = "";

        $scope.hasNoSelectedIndustryPartnerAndIndustrySupplierCapabilities = function() {
            return $scope.hasNoSelectedIndustryPartnerCapabilities() && $scope.hasNoSelectedIndustrySupplierCapabilities();
        }

        $scope.hasNoSelectedIndustryPartnerCapabilities = function() {

        	var filteredIndustryPartnerCapabilities = [];
        	if (vm.serviceProviderIndustryPartnerCapabilities) {
                console.log('Checking service provider industry partner capabilities');
        		filteredIndustryPartnerCapabilities = vm.serviceProviderIndustryPartnerCapabilities.filter(function (el) {
                    return el != null && el != '';
                });
            }

        	if (filteredIndustryPartnerCapabilities.length > 0) {
        	    console.log('hasNoSelectedIndustryPartnerCapabilities - returning false, at least one industry partner capability selected');
        		return false;
        	}

            console.log('hasNoSelectedIndustryPartnerCapabilities - no industry partner capabilities selected');
        	return true;
        }

        $scope.hasNoSelectedIndustrySupplierCapabilities = function(){
        	var filteredIndustrySupplierCapabilities = [];

        	if (vm.serviceProviderIndustrySupplierCapabilities) {
        	    console.log('Checking service provider industry supplier capabilities');
        	    filteredIndustrySupplierCapabilities = vm.serviceProviderIndustrySupplierCapabilities.filter(function (el) {
                    return el != null && el != '';
                });
            }

        	if (filteredIndustrySupplierCapabilities.length > 0) {
        	    console.log('hasNoSelectedIndustrySupplierCapabilities - returning false, at least one of industry supplier capability selected');
        		return false;
        	}

            console.log('hasNoSelectedIndustrySupplierCapabilities - no industry partner capabilities selected');
        	return true;
        }

        // Checks whether no selection exists for any capabilities in Moving, Relocation, and US Government capability
        // groups
        $scope.checkMovingRelocationUSGovt = function (){

        	var filteredMovingCapabilities = [];
        	if (vm.serviceProviderMovingCapabilities) {
        	    console.log('Checking service provider moving capabilities');
                filteredMovingCapabilities = vm.serviceProviderMovingCapabilities.filter(function (el) {
                    console.log('Checking service provider moving capabilities, element: ' + el);
                    return el != null && el != '';
                });
            }

        	var filteredReloCapabilities = [];
        	if (vm.serviceProviderReloCapabilities) {
                console.log('Checking service provider relocation capabilities');
                filteredReloCapabilities = vm.serviceProviderReloCapabilities.filter(function (el) {
                    console.log('Checking service provider relocation capabilities, element: ' + el);
                    return el != null && el != '';
                });
            }

        	var filteredUSGovCapabilities = [];
        	if (vm.serviceProviderUSGovCapabilities) {
        	    console.log('Checking service provider US government capabilities');
                filteredUSGovCapabilities = vm.serviceProviderUSGovCapabilities.filter(function (el) {
                    console.log('Checking service provider US government capabilities, element: ' + el);
                    return el != null && el != '';
                });
            }

        	if (filteredMovingCapabilities.length > 0 || filteredReloCapabilities.length > 0 || filteredUSGovCapabilities.length > 0) {
        	    console.log('Returning false, at least one of moving, relocation or US government capabilities found');
         		return false;
         	}

         	return true;
        }

        vm.openCapabilityMessage = openCapabilityMessage;

        function openCapabilityMessage() {
        	var modalInstance = $uibModal.open({
        	templateUrl: 'app/entities/service-providers/capabilityPopUp.html',
        	controller: 'MembershipModalContentCtrl',
        });
        }

        $scope.inValidCity = [] ;
        $scope.cityHasError = false;

        $scope.validateCity  = function (index, city){
        	if(city != null && city.includes(",")){
        		$scope.cityHasError = true;
        		$scope.inValidCity[index] = true;
        	}
        	else{
        		$scope.inValidCity[index] = false;
        		$scope.cityHasError = false;
        	}

        }

        $scope.downloadLetter = function(event){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";

            var url = escape('api/generate-certificate/IAM_LETTER/'+vm.serviceProviders.id+'/'+vm.taxId);

            $http.get(url, {responseType: 'arraybuffer'}).then(function successCallback(data) {

                var file = new Blob([data.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
            });

        };

        $scope.downloadCert = function(event){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";

            var url = escape('api/generate-certificate/IAM_CERT/'+vm.serviceProviders.id+'/'+vm.taxId);

            $http.get(url, {responseType: 'arraybuffer'}).then(function successCallback(data) {

                var file = new Blob([data.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
            });
        };

        $scope.downloadItmcCertificate = function(event){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";

            // API endpoint expects taxId as part of the path
            var url = escape('api/generate-certificate/ITMC_CERT/'+vm.serviceProviders.id +'/' + vm.taxId);

            $http.get(url, {responseType: 'arraybuffer'}).then(function successCallback(data) {

                var file = new Blob([data.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
            });
        };

        $scope.showIamtrustedAffirmationForm = function(event){
            console.log('Invoked form');

            event.preventDefault();
            event.stopPropagation();

            $state.go('service-providers.edit.iamtrusted-affirmation-form');
        }

        vm.pcgFlag = false;
        vm.setPCGFlags = function(sps){
            if(sps && sps.compliance){
                var com =  sps.compliance;
                for(var j=0; j< com.length; j++){
                    if(com[j] && com[j].awardedto === 'PCG'){
                        vm.pcgFlag = true;
                    }
                }
            }

        };
        vm.setPCGFlags(vm.serviceProviders);

        vm.previewInvoice = function(event, invoiceId){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";

            $http.get('api/generate-invoice/'+invoiceId, {responseType: 'arraybuffer'}).then(function successCallback(data) {

                var file = new Blob([data.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
            });
        };

        vm.activeQual = 0;
        vm.getActiveQual = function (memberContacts) {
            vm.activeQual = 0;
            for (i = 0; i < memberContacts.contactQualifications.length; i++) {
                if (new Date(memberContacts.contactQualifications[i].expDate) > Date.now()) {
                    vm.activeQual++;
                }
            }
            return vm.activeQual;
        };

        vm.previewReceipt = function(event, invoiceId){

            event.preventDefault();
            event.stopPropagation();

            if( _.isEmpty(vm.taxId) ) vm.taxId = " ";

            $http.get('api/generate-receipt/'+invoiceId, {responseType: 'arraybuffer'}).then(function successCallback(data) {

                var file = new Blob([data.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }, function errorCallback(response) {
            });

        };

        $scope.setStatusDate = function(id){
            vm.serviceProviderAssociations[id].statusDate = Date.now();
        };

        Principal.identity().then(function (account) {
            console.log('Getting principal account: ' + account);
            if (vm.serviceProviders && vm.iamTrustedAffirmation && !vm.serviceProviders.itmcAffirmationName && vm.serviceProviders.itmcOriginAffirmationDate == null) {
                vm.iamTrustedAffirmation.affirmationName = account.firstName + ' ' + account.lastName;
                vm.iamTrustedAffirmation.submitterName = vm.iamTrustedAffirmation.affirmationName;
            }
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            for (var i = 0; i < $scope.account.authorities.length; i++) {
                switch($scope.account.authorities[i]) {
                    case 'ROLE_ADMIN':
                        $scope.isAdminUser = true;
                        $scope.isMemberAdminUser = true;
                        $scope.isFinanceAdminUser = true;
                    case 'ROLE_ASSN_FINANCE':
                        $scope.isMemberAdminUser = true;
                        $scope.isFinanceAdminUser = true;
                    case  'ROLE_SP_FINANCE':
                        $scope.isFinanceAdminUser = true;
                    case  'ROLE_SP_ADMIN':
                        $scope.isFinanceAdminUser = true;
                }
            }

            // Don't return an error if a member profile is not found to avoid getting 'not found' error notifications
            // when MX admins view service provider's CRM notes
            var memberProfileUrl = '/api/member-profile?suppressNotFound=true';
            $http.get(memberProfileUrl).then(function(res){
                var memberProfile = res.data;
                if (memberProfile && memberProfile.contactControls){
                    console.log("Processing member profile: " + memberProfile);
                    var assignedMemberAdmin = function (contactControl) {
                        return contactControl.spId === vm.serviceProviders.id && contactControl.adminFlag == true;
                    };
                    $scope.isAssignedMemberAdminUser = _.some(memberProfile.contactControls, assignedMemberAdmin);
                    console.log('Assigned member admin: ' + $scope.isAssignedMemberAdminUser);
                }
            });
        });

        vm.updateParentIdMessage = null;

        vm.updateParentId = function(contact, id){
            $http.put('/api/update-sp-id/email/'+contact.email+'/spid/'+id).then(
                function(response){
                    // console.log("Updated : "+response.data);
                    if(response.data && response.data.resp && response.data.resp !== 'Ok'){
                        vm.updateParentIdMessage = response.data.resp ;
                    }else{
                        vm.updateParentIdMessage = null;
                    }
                }
            );
        };

        $scope.setAddId = function (addid) {
            $scope.selectedAddressId = addid;
        };

        $scope.setReviewDate = function (obj) {
            obj.valReviewDate = '';
        };

        vm.ifSPILMorISM = function(){

            if (vm.serviceProviders.memberAssociations && !_.isUndefined(vm.serviceProviders.memberAssociations))
                for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                    if(vm.serviceProviders.memberAssociations[i]) {
                        var memberType = vm.serviceProviders.memberAssociations[i].memberType;
                        if (memberType &&
                            (memberType.id === 1660 || memberType.id === 1661 || memberType.id === 1676 || memberType.id === 1678
                            || memberType.id === 1679 || memberType.id === 5555 || memberType.id === 5556 || memberType.id === 5557)) {
                            return true;
                        }
                    }
                }
            return false;
        };

        $scope.setValApprovedDate = function (approved, obj, id) {

            if (approved) {

                if (vm.serviceProviders.memberAssociations && !_.isUndefined(vm.serviceProviders.memberAssociations)) {
                    for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                        if(vm.serviceProviders.memberAssociations[i].memberAssociation.id === id){
                            vm.serviceProviders.memberAssociations[i].valApproved = approved;
                            vm.serviceProviders.memberAssociations[i].valApprovedDate = new Date();
                        }
                    }
                }

                obj.valApproved = approved;
                obj.valApprovedDate = new Date();

                if (_.isUndefined(obj.valExpiryDate)
                    || _.isNull(obj.valExpiryDate)) {


                    if (vm.serviceProviders.memberAssociations
                        && !_.isUndefined(vm.serviceProviders.memberAssociations)) {
                        for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                            if(vm.serviceProviders.memberAssociations[i].memberAssociation.id === id){
                                vm.serviceProviders.memberAssociations[i].valReviewDate = new Date();
                                (vm.serviceProviders.memberAssociations[i].valReviewDate).setDate((new Date()).getDate() + 183);
                            }
                        }
                    }

                    obj.valReviewDate = new Date();
                    (obj.valReviewDate).setDate((new Date()).getDate() + 183);
                }
            }
            else {
                obj.valApprovedDate = null;
            }
        };

        $scope.setValApprovedDateCerts = function (approved, obj, id) {
            if (approved) {

                if (vm.serviceProviders.quality && !_.isUndefined(vm.serviceProviders.quality)) {
                    for (var i = 0; i < vm.serviceProviders.quality.length; i++) {
                        if(vm.serviceProviders.quality[i] && vm.serviceProviders.quality[i].certificateType && vm.serviceProviders.quality[i].certificateType.id === id){
                            vm.serviceProviders.quality[i].valApproved = approved;
                            vm.serviceProviders.quality[i].valApprovedDate = new Date();
                        }
                    }
                }

                obj.valApproved = approved;
                obj.valApprovedDate = new Date();
                if (_.isUndefined(obj.expirydate)
                    || _.isNull(obj.expirydate)) {


                    if (vm.serviceProviders.quality && !_.isUndefined(vm.serviceProviders.quality)) {
                        for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                            if(vm.serviceProviders.quality[i] && vm.serviceProviders.quality[i].certificateType && vm.serviceProviders.quality[i].certificateType.id === id){
                                vm.serviceProviders.quality[i].valReviewDate = new Date();
                                (vm.serviceProviders.quality[i].valReviewDate).setDate((new Date()).getDate() + 183);
                            }
                        }
                    }

                    obj.reviewdate = new Date();
                    (obj.reviewdate).setDate((new Date()).getDate() + 183);
                }
            }
            else {
                obj.valApprovedDate = null;
            }
        };

        $scope.setValApprovedDateComplianceCerts = function (approved, obj, id) {
            if (approved) {

                if (vm.serviceProviders.compliance && !_.isUndefined(vm.serviceProviders.compliance)) {
                    for (var i = 0; i < vm.serviceProviders.compliance.length; i++) {
                        if(vm.serviceProviders.compliance[i].certificateType.id === id){
                            vm.serviceProviders.compliance[i].valApproved = approved;
                            vm.serviceProviders.compliance[i].valApprovedDate = new Date();
                        }
                    }
                }

                obj.valApproved = approved;
                obj.valApprovedDate = new Date();
                if (_.isUndefined(obj.expirydate) || _.isNull(obj.expirydate)) {

                    if (vm.serviceProviders.compliance && !_.isUndefined(vm.serviceProviders.compliance)) {
                        for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                            if(vm.serviceProviders.compliance[i] && vm.serviceProviders.compliance[i].certificateType && vm.serviceProviders.compliance[i].certificateType.id === id){
                                vm.serviceProviders.compliance[i].valReviewDate = new Date();
                                (vm.serviceProviders.compliance[i].valReviewDate).setDate((new Date()).getDate() + 183);
                            }
                        }
                    }

                    obj.reviewdate = new Date();
                    (obj.reviewdate).setDate((new Date()).getDate() + 183);
                }
            }
            else {
                obj.valApprovedDate = null;
            }
        };

        $scope.setValApprovedDateDestinationServiceGuides = function (approved, obj, id) {
            if (approved) {

                if (vm.serviceProviders.destinationServices && !_.isUndefined(vm.serviceProviders.destinationServices)) {
                    for (var i = 0; i < vm.serviceProviders.destinationServices.length; i++) {
                        if (vm.serviceProviders.destinationServices[i].certificateType.id === id) {
                            vm.serviceProviders.destinationServices[i].valApproved = approved;
                            vm.serviceProviders.destinationServices[i].valApprovedDate = new Date();
                        }
                    }
                }

                obj.valApproved = approved;
                obj.valApprovedDate = new Date();
                if (_.isUndefined(obj.expirydate) || _.isNull(obj.expirydate)) {

                    if (vm.serviceProviders.destinationServices && !_.isUndefined(vm.serviceProviders.destinationServices)) {
                        for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                            if(vm.serviceProviders.destinationServices[i] && vm.serviceProviders.destinationServices[i].certificateType && vm.serviceProviders.destinationServices[i].certificateType.id === id){
                                vm.serviceProviders.destinationServices[i].valReviewDate = new Date();
                                (vm.serviceProviders.destinationServices[i].valReviewDate).setDate((new Date()).getDate() + 183);
                            }
                        }
                    }

                    obj.reviewdate = new Date();
                    (obj.reviewdate).setDate((new Date()).getDate() + 183);
                }
            }
            else {
                obj.valApprovedDate = null;
            }
        };

        $scope.iamFilter = function(association){

            return association.flex2 === 'Y';
        };

        $scope.nonIamFilter = function(association){

            return association.flex2 !== 'Y';
        };


        $scope.publishedInvoices = function(invoice){
          return invoice.invoiceStatus.lookupcode === 'Published' && invoice.legacy === false ;
        };

        $scope.byMembershipType = function(associationId){
            return function(memberType) {
                return memberType.flex1 === associationId;
            }
        };

        $scope.setReviewDateCerts = function (obj) {
            obj.reviewdate = '';
        };

        vm.getValidationCheclkist = getValidationCheclkist;
        $scope.validationCheckList = null;
        getValidationCheclkist(vm.serviceProviders.id);

        function getValidationCheclkist(id) {
            if (vm.serviceProviders.mxstatus != null && (vm.serviceProviders.mxstatus.lookupcode === '3' ||
                vm.serviceProviders.mxstatus.lookupcode === '4' || vm.serviceProviders.mxstatus.lookupcode === '5')) {

                $http.get('/api/validationchecklist/' + id).then(function (response) {
                    $scope.validationCheckList = response.data;
                });
            }
        }

        $scope.getValidationMessage = function (v) {
            var message = '';
            var okstyle = 'glyphicon&nbsp;glyphicon-ok-sign&nbsp;icon-success';
            var nokstyle = 'glyphicon&nbsp;glyphicon-remove-sign&nbsp;icon-failure';

            // //console.log('creating message '+v.element);

            if (v.category == 'Quality') {

                message = '<b>Standards and Certifications:</b>';
                if (!v.valApproved) {
                    message = message + ' ' + v.element + ' is missing required supporting information.';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                } else {
                    message = message + ' ' + v.element + ' has been validated.';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                }

            } else if (v.category == 'Membership') {

                message = '<b>Membership: </b>';
                if (!v.valApproved) {
                    message = message + ' ' + v.element + ' is missing required supporting information.';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                } else {
                    message = message + ' ' + v.element + ' has been validated.';
                    message = '<span>' + message + '</span>';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                }

            } else if (v.category == 'Years in Service') {

                message = '<b>Years in Service: </b>';
                if (!v.valApproved) {
                    message = message + ' ' + v.element + ' is missing required supporting information.';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                } else {
                    message = message + ' ' + v.element + ' has been validated.';
                    message = '&nbsp;&nbsp;<span class=validation-message>' + message + '</span>';
                }
            }

            return $sce.trustAsHtml(message);
        };

        vm.serviceProviderAssociations = [];
        $.each(
            vm.serviceProviders.memberAssociations,
            function (index, memberAssociation) {
                vm.serviceProviderAssociations[memberAssociation.memberAssociation.id] = memberAssociation;
           }
        );

        vm.serviceProviderQualities = [];
        $.each(
            vm.serviceProviders.quality,
            function (index, quality) {
                vm.serviceProviderQualities[quality.certificateType.id] = quality;
            }
        );

        vm.serviceProviderCompliance = [];
        $.each(
            vm.serviceProviders.compliance,
            function (index, compliance) {
                vm.serviceProviderCompliance[compliance.certificateType.id] = compliance;
            }
        );

        vm.serviceProviderDestinationServices = [];
        $.each(
            vm.serviceProviders.destinationServices,
            function (index, destinationService) {
                vm.serviceProviderDestinationServices[destinationService.dspType.id] = destinationService;
            }
        );

        vm.serviceProviderMovingCapabilities = [];
        $.each(
            vm.serviceProviders.movingCapabilities,
            function (index, movingCapability) {
                vm.serviceProviderMovingCapabilities[movingCapability.capabilityType.id] = movingCapability;
            }
        );

        vm.serviceProviderServiceScopes = [];
        $.each(
            vm.serviceProviders.scopeCapabilities,
            function (index, scopeCapability) {
                vm.serviceProviderServiceScopes[scopeCapability.capabilityType.id] = scopeCapability;
            }
        );

        vm.serviceProviderReloCapabilities = [];
        $.each(
            vm.serviceProviders.relocationCapabilities,
            function (index, reloCapability) {
                vm.serviceProviderReloCapabilities[reloCapability.capabilityType.id] = reloCapability;
            }
        );

        vm.serviceProviderIndustryPartnerCapabilities = [];
        $.each(
            vm.serviceProviders.industryPartnerCapabilities,
            function (index, industryPartnerCapability) {
                console.log('Setting industry partner capabilities, index: ' + index + ', industry partner capability: ' + industryPartnerCapability);
                vm.serviceProviderIndustryPartnerCapabilities[industryPartnerCapability.capabilityType.id] = industryPartnerCapability;
            }
        );

        vm.serviceProviderIndustrySupplierCapabilities = [];
        $.each(
            vm.serviceProviders.industrySupplierCapabilities,
            function (index, industrySupplierCapability) {
                console.log('Setting industry supplier capabilities, index: ' + index + ', industry supplier capability: ' + industrySupplierCapability);
                vm.serviceProviderIndustrySupplierCapabilities[industrySupplierCapability.capabilityType.id] = industrySupplierCapability;
            }
        );

        vm.serviceProviderUSGovCapabilities = [];
        $.each(
            vm.serviceProviders.usGovtCapabilities,
            function (index, usGovtCapability) {
                vm.serviceProviderUSGovCapabilities[usGovtCapability.capabilityType.id] = usGovtCapability;
            }
        );

        vm.serviceProviderLangCapabilities = [];
        $.each(
            vm.serviceProviders.languageCapabilities,
            function (index, langCapability) {
                vm.serviceProviderLangCapabilities[langCapability.capabilityType.id] = langCapability;
            }
        );

        $scope.updateAddDesc = function (idx, val) {
            var currentSelected = $filter('filter')($scope.vm.addressTypes, {id: val})[0];
            if (typeof vm.serviceProviders.serviceproviderAddresses[idx].description === 'undefined' ||
                vm.serviceProviders.serviceproviderAddresses[idx].description.length === 0
            )
                vm.serviceProviders.serviceproviderAddresses[idx].description = currentSelected.lookupvalue;

        };
        $scope.setFunctionalRoleId = function (id) {
            $scope.selectedFunctionalRoleId = id;
        };
        $scope.addAddress = false;

        $scope.updateParentId = function () {
            vm.parentSP = ServiceProviders.get({id: vm.serviceProviders.serviceProvidersId});
        };

        $scope.addNewAddress = function (event) {
            event.preventDefault();
            event.stopPropagation();
            $scope.addAddress = true;

            var serviceproviderAddress = new Object();
            $scope.splitAddressPhoneNumbers(serviceproviderAddress);

            if (vm.serviceProviders.serviceproviderAddresses) {
                $scope.addressCount = vm.serviceProviders.serviceproviderAddresses.length;
                // vm.serviceProviders.serviceproviderAddresses = [];
                vm.serviceProviders.serviceproviderAddresses[$scope.addressCount] = serviceproviderAddress;
                _.reverse(vm.serviceProviders.serviceproviderAddresses);

            }
            else {
                $scope.addressCount = 0;
                vm.serviceProviders.serviceproviderAddresses = [];
                vm.serviceProviders.serviceproviderAddresses[0] = serviceproviderAddress;
            }
        };

        $scope.setPhonePrefix = function (idx, val) {
            var countryCode = $filter('filter')($scope.vm.countryCodes, {lookupvalue: val}, true)[0].flex3;

            vm.mainAddressPhonePrefix = countryCode;
            vm.serviceProviders.serviceproviderAddresses[idx].phonePrefix = countryCode;

            // update country code in all phones of this address

            $.each
            (
                vm.serviceProviders.serviceproviderAddresses[idx].phoneEditFields,
                function (addressPhoneField, phoneEditField) {
                    phoneEditField["countryCode"] = countryCode;

                }
            )
            ;

            // update country code in all phones in all contacts if this address
			// is main address

            if (vm.serviceProviders.serviceproviderAddresses[idx].addressType.id == '1530') {
                $.each
                (
                    vm.serviceProviders.serviceproviderContacts,
                    function (index, serviceproviderContact) {
                        $.each
                        (
                            serviceproviderContact.phoneEditFields,
                            function (contactPhoneField, phoneEditField) {
                                phoneEditField["countryCode"] = countryCode;

                            }
                        );
                    }
                )
                ;
            }
        };

        function setEmptyServiceProvider(){
        	vm.newServiceProvider =[];
        	vm.newServiceProvider.push(vm.serviceProviders.serviceproviderContacts[0]);
        	/*
			 * let selected
			 * =$filter('filter')(vm.serviceProviders.serviceproviderContacts,
			 * {'lookupId':emailSubId})[0];
			 */

        }

        function setPhonePrefixes(index){
            for (var i = 0; i < vm.serviceProviders.serviceproviderAddresses.length; i++) {
                if (vm.serviceProviders.serviceproviderAddresses[i].addressType.id == '1530') {
                    var phonePrefix =  vm.serviceProviders.serviceproviderAddresses[i].phonePrefix;

                    vm.mainAddressPhonePrefix = phonePrefix;

                    vm.serviceProviders.serviceproviderContacts[index]
                        .phoneEditFields['officephone'].countryCode
                        = phonePrefix;
                    vm.serviceProviders.serviceproviderContacts[index]
                        .phoneEditFields['cellphone'].countryCode
                        = phonePrefix;
                }
            }
        }

        $scope.toShow = function (val) {
            if (!_.isNil(vm.serviceProviders.mxstatus))
                if (val == vm.serviceProviders.mxstatus.id) {
                    return true;
                }
            return false;
        };

        $scope.setMxStatus = function () {
            // //console.log('Setting MX Status :::
			// '+vm.serviceProviders.mxstatus.id);
            // vm.serviceProviders.mxstatus =
			// $scope.editForm['serviceProviders.mxstatus'];
        };

        if (!_.isNil(vm.serviceProviders.mxstatus) && !_.isEmpty(vm.serviceProviders.mxstatus)) {
            // ////console.log('vm.mxStatus[2]'+vm.mxStatus);
            // $scope.currentmxstatus = vm.mxStatus[2].id+'';
            // $scope.currentmxstatus = vm.mxStatus[1].lookupvalue;
        }

        $scope.isSelected = function (a, b) {
            if ((a == b)) {
                return "selected";
            }
            return "";
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
        	$scope.$dismiss('cancel');
        }

        function isUndefinedOrNull(value) {
            return value === null || value === undefined;
        }

        $scope.showSpinner = false;

        vm.validationErrorMessage = "";

        function saveCRM() {

            vm.isSaving = true;

            if(vm.customerNotes && vm.customerNotes.id !== null
                    && !_.isUndefined( vm.customerNotes.id)) {
                var sProviders = vm.serviceProviders;
                delete sProviders.customerNotes;
                vm.customerNotes.serviceProviders = sProviders;
                vm.customerNotes.actor = $scope.actor.firstName+' '+$scope.actor.lastName;
                CustomerNotes.update(vm.customerNotes).$promise.then(onUpdatesNotesSuccess).catch(onUpdatesNotesFailure);
            }else{
                vm.customerNotes.serviceProviders = vm.serviceProviders;
                vm.customerNotes.actor = $scope.actor.firstName+' '+$scope.actor.lastName;
                CustomerNotes.save(vm.customerNotes).$promise.then(onSaveNotesSuccess).catch(onSaveNotesFailure);
            }
        }

        function saveIamtrustedAffirmation() {
            console.log("Save IAMTrusted affirmation" + vm.iamTrustedAffirmation);

            vm.iamTrustedAffirmation.error = false;
            vm.isSaving = true;

            // Populate
            vm.serviceProviders.itmcAffirmationName = vm.iamTrustedAffirmation.affirmationName;
            vm.serviceProviders.itmcAffirmationTitle = vm.iamTrustedAffirmation.affirmationTitle;
            vm.serviceProviders.itmcSubmitterName = vm.iamTrustedAffirmation.submitterName;

            vm.serviceProviders = ServiceProviders.saveIamtAffirmation(vm.serviceProviders, onSaveAffirmationFormSuccess, onSaveAffirmationFormError);
        }

        function checkForMainAddress(){
            if (!vm.serviceProviders || !vm.serviceProviders.serviceproviderAddresses) {
                return false;
            }

        	var hasMainAddress = false;
            for (var i = 0; i < vm.serviceProviders.serviceproviderAddresses.length; i++){
                if(vm.serviceProviders.serviceproviderAddresses[i].addressType.id == '1530'){
                    hasMainAddress = true;
                    break;
                }
            }
            return hasMainAddress;
        }

        function checkMultipleMainAddress(){

        	if (!vm.serviceProviders || !vm.serviceProviders.serviceproviderAddresses) {
        	    return false;
        	}

        	var hasMainAddress = 0;
            for (var i = 0; i < vm.serviceProviders.serviceproviderAddresses.length; i++){
                if(vm.serviceProviders.serviceproviderAddresses[i].addressType.id == '1530'){
                    hasMainAddress++;
                }
            }
           return (hasMainAddress > 1);
        }

        function checkMultipleBillingAddress(){
        	if (!vm.serviceProviders || !vm.serviceProviders.serviceproviderAddresses) {
        	    return false;
        	}

        	var hasAddress = 0;
            for (var i = 0; i < vm.serviceProviders.serviceproviderAddresses.length; i++){
                if(vm.serviceProviders.serviceproviderAddresses[i].addressType.id == '1533'){
                    hasAddress++;
                }

            }
           return (hasAddress > 1);
        }

        function validateMemberAssociationTypes() {
            var validationMessage = '';
            if (vm.serviceProviderAssociations) {
                if (vm.serviceProviders.memberAssociations) {
                    for (var k = 0; k < vm.serviceProviders.memberAssociations.length; k++) {
                         var associationTypeValidationMessage = validateMemberAssociationType(vm.serviceProviders.memberAssociations[k]);
                         if (associationTypeValidationMessage && associationTypeValidationMessage.length > 0) {
                            validationMessage += (associationTypeValidationMessage + '. ');
                         }
                    }
                }
            }

            return validationMessage;
        }

        function validateMemberAssociationType(memberAssociation) {
            var validationMessage = '';

            var activeStatus = (memberAssociation.memberStatus && memberAssociation.memberStatus.lookupcode) === 'ACT';
            console.log('Active status: ' + activeStatus);

            // If status is not active any value can be entered
            if (!activeStatus) {
                return '';
            }

            var memberTypeFlex1 = (memberAssociation.memberType && memberAssociation.memberType.flex1);
            var validateType =  (memberTypeFlex1 === '800');
            console.log('Validate type: ' + validateType + ', flex1: ' + memberTypeFlex1);

            if (!validateType) {
                return '';
            }

            var memberTypeFlex6 = (memberAssociation.memberType && memberAssociation.memberType.flex6);
            var validType = memberTypeFlex6 === 'Y';
            console.log('Valid type: ' + validType + ', flex6: ' + memberTypeFlex6);

            if (!validType) {
                var memberAssociationLookupValue = memberAssociation.memberAssociation && memberAssociation.memberAssociation.lookupvalue;
                validationMessage = 'Membership type value [' + (memberAssociation.memberType && memberAssociation.memberType.lookupvalue) + '] cannot be specifed for membership [' + memberAssociationLookupValue + '] when status is Active';
            }

            return validationMessage;
        }

        function save() {
            if(!$scope.editForm.$valid || $scope.cityHasError) {
                $scope.editForm.submitted=true;
                vm.validationErrorMessage = "We could not save changes ! Please enter data in the fields marked red and save again.";
                return false;
            }

          // MX-761
            if(checkMultipleMainAddress()){
            	$scope.editForm.submitted=true;
            	vm.validationErrorMessage = "We could not save changes ! You may enter only one Main address.";
                return false;
            }

            if(checkMultipleBillingAddress()){
            	$scope.editForm.submitted=true;
            	vm.validationErrorMessage = "We could not save changes ! You may enter only one Billing address.";
                return false;
            }

            if(!checkForMainAddress()){
            	$scope.editForm.submitted=true;
            	vm.validationErrorMessage = "We could not save changes ! You must enter a complete Main Address before this record can be saved.";
                return false;
            }

            var memberAssocationValidationMessage = validateMemberAssociationTypes();
            if (memberAssocationValidationMessage && memberAssocationValidationMessage.length > 0) {
                $scope.editForm.submitted=true;
                vm.validationErrorMessage = memberAssocationValidationMessage;
                return false;
            }

            vm.validationErrorMessage = "";

            $scope.showSpinner = true;
            $scope.mxvaldationMessage = false;
            var a = angular.element('#field_mxstatus').val();
            var contactStatus = angular.element('#field_contactStatus').val();

            if(vm.serviceProviders.website && !_.startsWith(vm.serviceProviders.website, 'http') )
                vm.serviceProviders.website = 'http://'+vm.serviceProviders.website;

            if(vm.serviceProviders.ratePortalAddress && !_.startsWith(vm.serviceProviders.ratePortalAddress, 'http') )
                vm.serviceProviders.ratePortalAddress = 'http://'+vm.serviceProviders.ratePortalAddress;

            vm.serviceProviders.quality = [];
            vm.serviceProviders.subscriptionControls = vm.subscriptionControls;

            if(vm.serviceProviderAssociations) {

                for (var i = 0; i < vm.serviceProviderAssociations.length; i++) {
                    if (vm.serviceProviders.memberAssociations) {

                        for (var k = 0; k < vm.serviceProviders.memberAssociations.length; k++) {
                            if( !isUndefinedOrNull(vm.serviceProviderAssociations[i])
                                && !isUndefinedOrNull(vm.serviceProviders.memberAssociations[k]))

                            if (vm.serviceProviderAssociations[i].memberAssociation.id
                                === vm.serviceProviders.memberAssociations[k].memberAssociation.id) {

                                vm.serviceProviders.memberAssociations[k] = vm.serviceProviderAssociations[i];

                                if(vm.serviceProviders.memberAssociations[k]
                                    && vm.serviceProviders.memberAssociations[k].memberType) {

                                    if ((vm.serviceProviders.memberAssociations[k].memberType.flex2 === 'Y'
                                        || vm.serviceProviders.memberAssociations[k].memberType.flex2 === 'A'
                                        || vm.serviceProviders.memberAssociations[k].memberType.flex2 === 'N'
                                        )
                                        && vm.serviceProviders.memberAssociations[k].memberStatus.id === 1201) {

                                        if (vm.serviceProviders.memberAssociations[k].memberType.flex2 === 'A') {
                                            console.log('save SP - setting RPP flag to true, ' + vm.serviceProviders.memberAssociations[k]);
                                            vm.serviceProviders.rppFlag = true;
                                        }

                                        vm.serviceProviders.memberAssociations[k].valApproved = true; 

                                        if(!vm.serviceProviders.memberAssociations[k].valApprovedDate)
                                            vm.serviceProviders.memberAssociations[k].valApprovedDate = new Date();

                                        if(vm.serviceProviders.memberAssociations[k].expiryDate)
                                            vm.serviceProviders.memberAssociations[k].valExpiryDate
                                                = vm.serviceProviders.memberAssociations[k].expiryDate;
                                        vm.serviceProviders.memberAssociations[k].valReviewDate = new Date();

                                    } else {
                                        vm.serviceProviders.memberAssociations[k] = vm.serviceProviderAssociations[i];
                                        if (vm.serviceProviders.rppFlag
                                            && vm.serviceProviders.memberAssociations[k].memberAssociation.id === 800
                                            && vm.serviceProviders.memberAssociations[k].memberStatus.id !== 1201) {

                                            vm.serviceProviders.rppFlag = false;
                                        }

                                        vm.serviceProviders.memberAssociations[k].valApproved = false; 
                                        vm.serviceProviders.memberAssociations[k].valApprovedDate = null; 
                                        vm.serviceProviders.memberAssociations[k].valExpiryDate = null;
                                        vm.serviceProviders.memberAssociations[k].valReviewDate = null;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            vm.serviceProviders.movingCapabilities = [];
            $.each(
                vm.serviceProviderMovingCapabilities,
                function (index, serviceProviderMov) {
                    if (serviceProviderMov) {
                        vm.serviceProviders.movingCapabilities.push(serviceProviderMov);
                    }
                }
            );

            vm.serviceProviders.scopeCapabilities = [];
            $.each(
                vm.serviceProviderServiceScopes,
                function (index, serviceProviderScope) {
                    if (serviceProviderScope) {
                        vm.serviceProviders.scopeCapabilities.push(serviceProviderScope);
                    }
                }
            );

            vm.serviceProviders.industryPartnerCapabilities = [];
            $.each(
                vm.serviceProviderIndustryPartnerCapabilities,
                function (index, serviceProviderIndustryPartnerCapability) {
                    if (serviceProviderIndustryPartnerCapability) {
                        console.log('Setting industry partner capabilities, index: ' + index + ', capability: ' + serviceProviderIndustryPartnerCapability);
                        vm.serviceProviders.industryPartnerCapabilities.push(serviceProviderIndustryPartnerCapability);
                    }
                }
            );

            vm.serviceProviders.relocationCapabilities = [];
            $.each(
                vm.serviceProviderReloCapabilities,
                function (index, serviceProviderRelo) {
                    if (serviceProviderRelo) {
                        vm.serviceProviders.relocationCapabilities.push(serviceProviderRelo);
                    }
                }
            );

            vm.serviceProviders.industrySupplierCapabilities = [];
            $.each(
                vm.serviceProviderIndustrySupplierCapabilities,
                function (index, serviceProviderIndustrySupplierCapability) {
                    if (serviceProviderIndustrySupplierCapability) {
                        console.log('Setting industry supplier capabilities, index: ' + index + ', capability: ' + serviceProviderIndustrySupplierCapability);
                        vm.serviceProviders.industrySupplierCapabilities.push(serviceProviderIndustrySupplierCapability);
                    }
                }
            );

            vm.serviceProviders.usGovtCapabilities = [];
            $.each(
                vm.serviceProviderUSGovCapabilities,
                function (index, usGovtCapability) {
                    if (usGovtCapability) {
                        vm.serviceProviders.usGovtCapabilities.push(usGovtCapability);
                    }
                }
            );

            vm.serviceProviders.languageCapabilities = [];
            $.each(
                vm.serviceProviderLangCapabilities,
                function (index, serviceProviderLang) {
                    if (serviceProviderLang) {
                        vm.serviceProviders.languageCapabilities.push(serviceProviderLang);
                    }
                }
            );

            $.each(
                vm.serviceProviderQualities,
                function (index, serviceProviderQuality) {
                    if (serviceProviderQuality) {
                        // update certificate short name
                        serviceProviderQuality.awardedto = serviceProviderQuality.certificateType.lookupvalue;

                        // store certificate
                        vm.serviceProviders.quality.push(serviceProviderQuality);
                    }
                }
            );

            vm.serviceProviders.compliance = [];
            $.each(
                vm.serviceProviderCompliance,
                function (index, serviceProviderCompliance) {
                    if (serviceProviderCompliance) {
                        // update certificate short name
                        serviceProviderCompliance.awardedto = serviceProviderCompliance.certificateType.lookupvalue;

                        // store certificate
                        vm.serviceProviders.compliance.push(serviceProviderCompliance);
                    }
                }
            );

            vm.serviceProviders.destinationServices = [];
            $.each(
                vm.serviceProviderDestinationServices,
                function (index, serviceProviderDestinationService) {
                    if (serviceProviderDestinationService) {
                        // store certificate
                        vm.serviceProviders.destinationServices.push(serviceProviderDestinationService);
                    }
                }
            );

            // glue phone numbers
            $.each
            (
                vm.serviceProviders.serviceproviderAddresses,
                function (index, serviceproviderAddress) {
                    $.each
                    (
                        addressPhoneFields,
                        function (addressPhoneField) {
                            var hasCountryCode = serviceproviderAddress && serviceproviderAddress.phoneEditFields &&
                                serviceproviderAddress.phoneEditFields[addressPhoneField] &&
                                serviceproviderAddress.phoneEditFields[addressPhoneField].countryCode;

                            var hasLocalNumber = serviceproviderAddress && serviceproviderAddress.phoneEditFields &&
                                serviceproviderAddress.phoneEditFields[addressPhoneField] &&
                                serviceproviderAddress.phoneEditFields[addressPhoneField].localNumber;

                            if (hasLocalNumber) {
                                var countryPrefix = hasCountryCode ? "+" + serviceproviderAddress.phoneEditFields[addressPhoneField].countryCode + " " : "";
                                serviceproviderAddress[addressPhoneField] = countryPrefix +
                                    serviceproviderAddress.phoneEditFields[addressPhoneField].localNumber;
                            }
                        }
                    );
                }
            )
            ;

            if (!_.isEmpty(vm.serviceProviders) && !_.isEmpty(vm.serviceProviders.mxstatus)
                && vm.serviceProviders.mxstatus.lookupcode == 1 && a == 2 && !$scope.activeProviderContactExists) {
                $scope.mxvaldationMessage = true;
                vm.isSaving = false;
                return false;
            }

            if (vm.serviceProviders.id !== null) {
                vm.serviceProviders.modifiedby = $scope.account.login;
                ServiceProviders.update(vm.serviceProviders, onSaveSuccess, onSaveError);

            } else {
                vm.serviceProviders.createdby = $scope.account.login;
                vm.serviceProviders = ServiceProviders.save(vm.serviceProviders, onSaveSuccess, onSaveError);
               // $state.go("service-providers",{'search':"\""+vm.serviceProviders.companylegalname+"\""});

            }
            vm.newServiceProvider =[];
        }

        function setCustomerNoteDisplayNote(customerNote) {
            var displayNoteLength = 100;
            customerNote.displayNote = (customerNote.note && customerNote.note.length < displayNoteLength) ?
                customerNote.note : (customerNote.note.substring(0, displayNoteLength) + " ...");
        }

        function onUpdatesNotesSuccess(result) {
            console.log('Update note success callback');
        	$scope.editForm.$valid = true;
            vm.customerNotes = {};
            $scope.actor = null;
            var index = _.findIndex(vm.customerNotesList, {id: result.id});
            // Set the display note before updating the list
            setCustomerNoteDisplayNote(result);
            vm.customerNotesList.splice(index, 1, result);
            vm.isSaving = false;
            $scope.editForm.$dirty = false;
        }

        function onUpdatesNotesFailure() {
            vm.isSaving = false;
        }

        function onSaveNotesSuccess(result) {
            console.log('Save note success callback');
        	$scope.editForm.$valid = true;
            vm.customerNotes = {};
            $scope.actor = null;
            // Set the display note before updating the list
            setCustomerNoteDisplayNote(result);
            vm.customerNotesList.push(result);
            vm.isSaving = false;
            $scope.editForm.$dirty = false;
        }

        function onSaveNotesFailure() {
            vm.isSaving = false;
        }

        function closeCustomerNotesDialog() {
        	$scope.$dismiss('cancel');

        }

        vm.newNote = function(){
            vm.customerNotes = {};
            $scope.actor = null;
           // vm.customerNotes.note = null;
        }

        vm.editNote = function(note){
            vm.customerNotes = note;
            for (var i = 0; i < $scope.actors.length; i++) {
                if (vm.customerNotes && $scope.actors[i].firstName
                    + ' ' + $scope.actors[i].lastName == vm.customerNotes.actor) {
                    $scope.actor = $scope.actors[i];
                    break;
                }
            }
            for (var i = 0; i < $scope.actions.length; i++) {
                if (vm.customerNotes && vm.customerNotes.action != null
                    && $scope.actions[i].id == vm.customerNotes.action.id) {
                    vm.customerNotes.action = $scope.actions[i];
                    break;
                }
            }

            vm.customerNotes.targetDate = DateUtils.convertDateTimeFromServer(vm.customerNotes.targetDate);
        }

        function onSaveAffirmationFormSuccess(result) {
            vm.isSaving = false;
            $scope.showSpinner = false;
            $scope.editForm.$dirty = false;

            // Close the affirmation dialog
            vm.clear();
        }

        function onSaveAffirmationFormError() {
            console.log("Save IAMTrusted affirmation error");
            vm.iamTrustedAffirmation.error = true;
            onSaveError();
        }

        function onSaveSuccess(result) {
            $scope.$emit('mobilityexchangeApp:serviceProvidersUpdate', result);
            $scope.editForm.$valid = true;

            // reset uploaded file view //

            $scope.uploadCertNow = [];
            $scope.uploadGuideNow = [];
            $scope.uploadMemberShipNow = [];
            $scope.establishedNow = [];
            $scope.profileImageNow = [];
            $scope.logoImageNow = [];
            $scope.businessImageNow = [];
            $scope.advImageNow = [];
            $scope.endoImageNow = [];
            $scope.uploadCert = [];
            $scope.uploadGuide = [];
            $scope.uploadMemberShip = [];
            $scope.established = [];
            $scope.profileImage = [];
            $scope.logoImage = [];
            $scope.businessImage = [];
            $scope.advImage = [];
            $scope.endoImage = [];
            $scope.bussinessImageUploadedBy = null;
        	$scope.bussinessImageUploadedDate = null;

            vm.isSaving = false;
            vm.serviceProviders = result;
            // Need to deal with dates format ('YYYY-MM-DD') if setup form data is invoked after file
            // delete (in that case transformation of response data is not directly triggered) and strings
            // instead of dates are passed in. As result of that corresponding form elements are not properly
            // initialized.
            if (result.businessRegExpiry && typeof result.businessRegExpiry.getTime !== 'function') {
                vm.serviceProviders.businessRegExpiry = new Date(result.businessRegExpiry);
            }

            // $state.go('service-providers.edit', {id: result.id}, {reload:
			// 'service-providers.edit'});
            $scope.showSpinner = false;
            vm.serviceProviders.modifieddate = null;

            setupFormData();

            getValidationCheclkist(vm.serviceProviders.id);
            $scope.getFileRef();

            $scope.editForm.$dirty = false;
        }

        function setupFormData(){
            $.each
            (
                vm.serviceProviders.serviceproviderAddresses,
                function (index, serviceproviderAddress) {
                    $scope.splitAddressPhoneNumbers(serviceproviderAddress);
                }
            )
            ;

            vm.serviceProviderAssociations = [];
            $.each(
                vm.serviceProviders.memberAssociations,
                function (index, memberAssociation) {
                    vm.serviceProviderAssociations[memberAssociation.memberAssociation.id] = memberAssociation;
                    // Need to deal with dates format ('YYYY-MM-DD') if setup form data is invoked after file
                    // delete (in that case transformation of response data is not directly triggered) and strings
                    // instead of dates are passed in. As result of that corresponding form elements are not properly
                    // initialized.
                    if (memberAssociation.valReviewDate && typeof memberAssociation.valReviewDate.getTime !== 'function') {
                        vm.serviceProviderAssociations[memberAssociation.memberAssociation.id].valReviewDate = new Date(memberAssociation.valReviewDate);
                    }
                    if (memberAssociation.valExpiryDate && typeof memberAssociation.valExpiryDate.getTime !== 'function') {
                        vm.serviceProviderAssociations[memberAssociation.memberAssociation.id].valExpiryDate = new Date(memberAssociation.valExpiryDate);
                    }
                }
            );

            vm.serviceProviderQualities = [];
            $.each(
                vm.serviceProviders.quality,
                function (index, quality) {
                    vm.serviceProviderQualities[quality.certificateType.id] = quality;
                    // Need to deal with dates format ('YYYY-MM-DD') if setup form data is invoked after file
                    // delete (in that case transformation of response data is not directly triggered) and strings
                    // instead of dates are passed in. As result of that corresponding form elements are not properly
                    // initialized.
                    if (quality.expirydate && typeof quality.expirydate.getTime !== 'function') {
                        vm.serviceProviderQualities[quality.certificateType.id].expirydate = new Date(quality.expirydate);
                    }
                    if (quality.reviewdate && typeof quality.reviewdate.getTime !== 'function') {
                        vm.serviceProviderQualities[quality.certificateType.id].reviewdate = new Date(quality.reviewdate);
                    }
                }
            );

            vm.serviceProviderCompliance = [];
            $.each(
                vm.serviceProviders.compliance,
                function (index, compliance) {
                    vm.serviceProviderCompliance[compliance.certificateType.id] = compliance;
                    // Need to deal with dates format ('YYYY-MM-DD') if setup form data is invoked after file
                    // delete (in that case transformation of response data is not directly triggered) and strings
                    // instead of dates are passed in. As result of that corresponding form elements are not properly
                    // initialized.
                    if (compliance.expirydate && typeof compliance.expirydate.getTime !== 'function') {
                        vm.serviceProviderCompliance[compliance.certificateType.id].expirydate = new Date(compliance.expirydate);
                    }
                    if (compliance.reviewdate && typeof compliance.reviewdate.getTime !== 'function') {
                        vm.serviceProviderCompliance[compliance.certificateType.id].reviewdate = new Date(compliance.reviewdate);
                    }
                }
            );

            vm.serviceProviderDestinationServices = [];
            $.each(
                vm.serviceProviders.destinationServices,
                function (index, destinationService) {
                    vm.serviceProviderDestinationServices[destinationService.dspType.id] = destinationService;
                    // Need to deal with dates format ('YYYY-MM-DD') if setup form data is invoked after file
                    // delete (in that case transformation of response data is not directly triggered) and strings
                    // instead of dates are passed in. As result of that corresponding form elements are not properly
                    // initialized.
                    if (destinationService.expirydate && typeof destinationService.expirydate.getTime !== 'function') {
                        vm.serviceProviderDestinationServices[destinationService.dspType.id].expirydate = new Date(destinationService.expirydate);
                    }
                    if (destinationService.reviewdate && typeof destinationService.reviewdate.getTime !== 'function') {
                        vm.serviceProviderDestinationServices[destinationService.dspType.id].reviewdate = new Date(destinationService.reviewdate);
                    }
                }
            );

            vm.serviceProviderMovingCapabilities = [];
            $.each(
                vm.serviceProviders.movingCapabilities,
                function (index, movingCapability) {
                    vm.serviceProviderMovingCapabilities[movingCapability.capabilityType.id] = movingCapability;

                }
            );

            vm.serviceProviderServiceScopes = [];
            $.each(
                vm.serviceProviders.scopeCapabilities,
                function (index, scopeCapability) {
                    vm.serviceProviderServiceScopes[scopeCapability.capabilityType.id] = scopeCapability;
                }
            );

            vm.serviceProviderReloCapabilities = [];
            $.each(
                vm.serviceProviders.relocationCapabilities,
                function (index, relocationCapability) {
                    vm.serviceProviderReloCapabilities[relocationCapability.capabilityType.id] = relocationCapability;
                }
            );

            vm.serviceProviderIndustryPartnerCapabilities  = [];
            $.each(
                vm.serviceProviders.industryPartnerCapabilities,
                function (index, industryPartnerCapability) {
                    console.log('Setting industry partner capabilities, index: ' + index + ', capability: ' + industryPartnerCapability);
                    vm.serviceProviderIndustryPartnerCapabilities[industryPartnerCapability.capabilityType.id] = industryPartnerCapability;
                }
            );

            vm.serviceProviderLangCapabilities = [];
            $.each(
                vm.serviceProviders.languageCapabilities,
                function (index, languageCapability) {
                    vm.serviceProviderLangCapabilities[languageCapability.capabilityType.id] = languageCapability;
                }
            );

            vm.serviceProviderIndustrySupplierCapabilities = [];
            $.each(
                vm.serviceProviders.industrySupplierCapabilities,
                function (index, industrySupplierCapability) {
                    console.log('Setting industry supplier capabilities, index: ' + index + ', capability: ' + industrySupplierCapability);
                    vm.serviceProviderIndustrySupplierCapabilities[industrySupplierCapability.capabilityType.id] = industrySupplierCapability;
                }
            );

            vm.serviceProviderUSGovCapabilities = [];
            $.each(
                vm.serviceProviders.usGovtCapabilities,
                function (index, usGovtCapability) {
                    vm.serviceProviderUSGovCapabilities[usGovtCapability.capabilityType.id] = usGovtCapability;
                }
            );
        }

        function transformServiceProvidersAfterFileDelete(data) {
            if (data) {
                data = angular.fromJson(data);
                if (data.businessRegExpiry) {
                    data.businessRegExpiry = DateUtils.convertLocalDateFromServer(data.businessRegExpiry);
                }

                if (data.createddate) {
                    data.createdDateForDisplay = data.createddate;
                }

                if (data.modifieddate) {
                    data.modifiedDateForDisplay = data.modifieddate;
                }

                $.each(
                    data.subscriptionControls,
                    function (index, subscriptionControl) {
                             subscriptionControl.startDate = DateUtils.convertLocalDateFromServer(subscriptionControl.startDate);
                             subscriptionControl.endDate = DateUtils.convertLocalDateFromServer(subscriptionControl.endDate);
                    }
                );

                $.each(
                    data.quality,
                    function (index, quality) {
                        quality.expirydate = DateUtils.convertLocalDateFromServer(quality.expirydate);
                        quality.reviewdate = DateUtils.convertLocalDateFromServer(quality.reviewdate);
                    }
                );

                $.each(
                    data.compliance,
                    function (index, compliance) {
                        compliance.expirydate = DateUtils.convertLocalDateFromServer(compliance.expirydate);
                        compliance.reviewdate = DateUtils.convertLocalDateFromServer(compliance.reviewdate);
                    }
                );

                $.each(
                    data.memberAssociations,
                    function (index, memberAssociations) {

                        memberAssociations.valExpiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.valExpiryDate);
                        memberAssociations.valReviewDate = DateUtils.convertLocalDateFromServer(memberAssociations.valReviewDate);

                        memberAssociations.statusDate = DateUtils.convertLocalDateFromServer(memberAssociations.statusDate);
                        memberAssociations.originalJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.originalJoinDate);
                        memberAssociations.currentJoinDate = DateUtils.convertLocalDateFromServer(memberAssociations.currentJoinDate);
                        memberAssociations.expiryDate = DateUtils.convertLocalDateFromServer(memberAssociations.expiryDate);
                        memberAssociations.terminationDate = DateUtils.convertLocalDateFromServer(memberAssociations.terminationDate);
                    }
                );
            }

            return data;
        }

        function onSaveError() {
            vm.isSaving = false;
            $scope.showSpinner = false;
        }

        vm.datePickerOpenStatus.createddate = false;
        vm.datePickerOpenStatus.modifieddate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.businessRegExpiry = false;
        $scope.contactQualExpMinDate = new Date();

        vm.openCalendarCtQuals = function(field, id, certDate){

            if(certDate) $scope.contactQualExpMinDate = certDate ;

            openCalendar(field, id);
        }


        function openCalendar(field, id) {

            if (id) {

                if (!vm.datePickerOpenStatus[field]) {
                    vm.datePickerOpenStatus[field] = [];
                }
                vm.datePickerOpenStatus[field][id] = true;
            } else {
                vm.datePickerOpenStatus[field] = true;
            }
        }

        $scope.iammembertype = "";

        function getIamMemberType(){
            var memberNumber ='';
            if(vm.serviceProviders.memberAssociations ) {
                for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                    if (vm.serviceProviders.memberAssociations[i]
                        && vm.serviceProviders.memberAssociations[i].memberType
                        && vm.serviceProviders.memberAssociations[i].memberStatus
                        && vm.serviceProviders.memberAssociations[i].memberStatus.lookupcode === 'ACT'
                    ) {
                        $scope.iammembertype = vm.serviceProviders.memberAssociations[i].memberType.lookupvalue
                                                            +' | '+$scope.iammembertype;
                        if(vm.serviceProviders.memberAssociations[i].memberNumber)
                            memberNumber = ' (ID #: '+vm.serviceProviders.memberAssociations[i].memberNumber+')';

                    }
                }
            }
            $scope.iammembertype = $scope.iammembertype + memberNumber;
        };

        getIamMemberType();

        vm.isCertToBeDisplayed = false;

        function isCertDisplayed(){
            if(vm.serviceProviders.memberAssociations ) {
                for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                    if (vm.serviceProviders.memberAssociations[i] !== null
                        && vm.serviceProviders.memberAssociations[i].memberAssociation.id === 800
                        && vm.serviceProviders.memberAssociations[i].memberStatus !== null
                        && vm.serviceProviders.memberAssociations[i].memberStatus.lookupcode == 'ACT'
                    ) {
                        // console.log(vm.serviceProviders.memberAssociations[i]);
                        vm.isCertToBeDisplayed = true;
                        break;
                    }
                }
            }
        };
        isCertDisplayed();

        $scope.fieldDisabled = true;

        $scope.isFieldDisabled = function(obj){

            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated;
                for (var i = 0; i < $scope.account.authorities.length; i++) {
                    if ($scope.account.authorities[i] === 'ROLE_ADMIN') {
                        $scope.isAdminUser = true;
                        $scope.fieldDisabled = false;
                        // return false;
                        // break;
                    } else {
                        if (!obj.id) {
                            $scope.fieldDisabled = false;
                            // return false;
                        }
                    }
                }
            });
        }

        $scope.setMovingCapability = function (movingCapability, id, event) {

        	if ($scope.hasNoSelectedIndustryPartnerAndIndustrySupplierCapabilities()) {
        		if (vm.serviceProviderMovingCapabilities[id]) {
                    delete vm.serviceProviderMovingCapabilities[id];
                } else {
                    vm.serviceProviderMovingCapabilities[id] =  {'capabilityType':movingCapability};
                }
        	} else {
        	    console.log('Clearing service provider moving capability, id: ' + id);
        		vm.serviceProviderMovingCapabilities[id] = false;
        		// Don't propagate the event to prevent the checkbox to stay selected on multiple clicks
        		$scope.suppressEvent(event);
        		openCapabilityMessage();
        	}
        };

        $scope.setServiceScope = function (serviceScope, id) {
            if (vm.serviceProviderServiceScopes[id]) {
                delete vm.serviceProviderServiceScopes[id];
            } else {
                vm.serviceProviderServiceScopes[id] =  {'capabilityType':serviceScope};
            }
        };

        $scope.setRelocationCapability = function (relocationCapability, id, event) {

        	if ($scope.hasNoSelectedIndustryPartnerAndIndustrySupplierCapabilities()) {
        		if (vm.serviceProviderReloCapabilities[id]) {
                    delete vm.serviceProviderReloCapabilities[id];
                } else {
                    vm.serviceProviderReloCapabilities[id] =  {'capabilityType':relocationCapability};
                }
        	} else {
        	    console.log('Clearing service provider relocation capability, id: ' + id);
        		vm.serviceProviderReloCapabilities[id] = false;
        		// Don't propagate the event to prevent the checkbox to stay selected on multiple clicks
        		$scope.suppressEvent(event);
        		openCapabilityMessage();
        	}
        };

        $scope.setMemberAssociations = function (memberAssociations, id) {
        	if (memberAssociations.flex2 === 'D' && $scope.ifUserNotAdmin()){

        		$uibModal.open({
        		      templateUrl: "app/entities/service-providers/membership-control-model.html",
        		      controller: "MembershipModalContentCtrl",
        		      size: '',
        		    });
        	} else {

	            if (vm.serviceProviderAssociations[id]) {
	                // console.log('deleting ... id '+id);
	                delete vm.serviceProviderAssociations[id];

	                // console.log(vm.serviceProviders.memberAssociations);

	                vm.serviceProviders.memberAssociations = _.filter(vm.serviceProviders.memberAssociations,
	                    function(o){ return o.memberAssociation.id !== id; });
	            }
	            else {
	                vm.serviceProviderAssociations[id] =  {'memberAssociation':memberAssociations};
	                vm.serviceProviders.memberAssociations.push(vm.serviceProviderAssociations[id]);
	            }
        	}
        };

        vm.managedMemberCreationError = [];

        $scope.setMemberAssociationsForManagedMembers = function (memberAssociations, id) {

            if(!vm.serviceProviderAssociations[id]||

                (   !_.isUndefined(vm.serviceProviderAssociations[id])
                &&
                    (
                        _.isUndefined(vm.serviceProviderAssociations[id].memberType)
                    ||  _.isUndefined(vm.serviceProviderAssociations[id].memberStatus)
                    ||  _.isUndefined(vm.serviceProviderAssociations[id].statusDate)

                    )
                )
            ){
                vm.managedMemberCreationError[id] = true;
                return false;
            }

            vm.managedMemberCreationError = false;

            var length = 0;
            // //console.log(angular.toJson(memberAssociations));
            if (vm.serviceProviders.memberAssociations == null) {
                length = 0;
            }
            else if (vm.serviceProviders.memberAssociations != null || vm.serviceProviders.memberAssociations.length > 0) {
                length = vm.serviceProviders.memberAssociations.length;
            }
            // //console.log('length == '+length);

            if (length == 0) {

                // //console.log('0 member capabilities');
                vm.serviceProviders.memberAssociations = [];
                vm.serviceProviders.memberAssociations[0] = new Object();
                vm.serviceProviders.memberAssociations[0].memberAssociation = memberAssociations;

                vm.serviceProviders.memberAssociations[0].memberNumber = vm.serviceProviderAssociations[id].memberNumber;
                vm.serviceProviders.memberAssociations[0].memberType = vm.serviceProviderAssociations[id].memberType;
                vm.serviceProviders.memberAssociations[0].memberStatus = vm.serviceProviderAssociations[id].memberStatus;
                vm.serviceProviders.memberAssociations[0].statusDate = vm.serviceProviderAssociations[id].statusDate;
                vm.serviceProviders.memberAssociations[0].originalJoinDate = vm.serviceProviderAssociations[id].originalJoinDate;
                vm.serviceProviders.memberAssociations[0].currentJoinDate = vm.serviceProviderAssociations[id].currentJoinDate;
                vm.serviceProviders.memberAssociations[0].expiryDate = vm.serviceProviderAssociations[id].expiryDate;
                vm.serviceProviders.memberAssociations[0].terminationDate = vm.serviceProviderAssociations[id].terminationDate;
                vm.serviceProviders.memberAssociations[0].terminationReason = vm.serviceProviderAssociations[id].terminationReason;

                vm.serviceProviders.iamID = vm.serviceProviderAssociations[id].memberNumber;

                vm.serviceProviderAssociations[id] = vm.serviceProviders.memberAssociations[0];
            } else {

                var checked = false;

                if (!_.isUndefined(vm.serviceProviders.memberAssociations))
                    for (var i = 0; i < vm.serviceProviders.memberAssociations.length; i++) {
                        if (vm.serviceProviders.memberAssociations[i] != null && id == vm.serviceProviders.memberAssociations[i].memberAssociation.id) {
                            // vm.serviceProviders.memberAssociations.splice(i,
							// 1);
                            checked = true;
                            break;
                        }
                    }

                if (!checked) {

                    vm.serviceProviders.memberAssociations[length] = new Object();
                    vm.serviceProviders.memberAssociations[length].memberAssociation = memberAssociations;

                    vm.serviceProviders.memberAssociations[length].memberNumber = vm.serviceProviderAssociations[id].memberNumber;
                    vm.serviceProviders.memberAssociations[length].memberType = vm.serviceProviderAssociations[id].memberType;
                    vm.serviceProviders.memberAssociations[length].memberStatus = vm.serviceProviderAssociations[id].memberStatus;
                    vm.serviceProviders.memberAssociations[length].statusDate = vm.serviceProviderAssociations[id].statusDate;
                    vm.serviceProviders.memberAssociations[length].originalJoinDate = vm.serviceProviderAssociations[id].originalJoinDate;
                    vm.serviceProviders.memberAssociations[length].currentJoinDate = vm.serviceProviderAssociations[id].currentJoinDate;
                    vm.serviceProviders.memberAssociations[length].expiryDate = vm.serviceProviderAssociations[id].expiryDate;
                    vm.serviceProviders.memberAssociations[length].terminationDate = vm.serviceProviderAssociations[id].terminationDate;
                    vm.serviceProviders.memberAssociations[length].terminationReason = vm.serviceProviderAssociations[id].terminationReason;

                    vm.serviceProviders.iamID = vm.serviceProviderAssociations[id].memberNumber;

                    vm.serviceProviderAssociations[id] = vm.serviceProviders.memberAssociations[length];
                }
            }

            // set RPP flag == true if member flex3 field is Y

            var selectedMemberType = _.find( vm.memberTypes, {id: vm.serviceProviderAssociations[id].memberType.id} );

            if (selectedMemberType && (selectedMemberType.flex2 === 'Y' || selectedMemberType.flex2 === 'A')
                && vm.serviceProviders.memberAssociations[length].memberStatus.id === 1201) {

                if (selectedMemberType.flex3 === 'A') {
                    console.log('setMemberAssociationsForManagedMembers - setting RPP flag to true: ' + vm.serviceProviders);
                    vm.serviceProviders.rppFlag = true;
                }

                vm.serviceProviders.memberAssociations[length].valApproved = true;
                if(!vm.serviceProviders.memberAssociations[length].valApprovedDate)
                    vm.serviceProviders.memberAssociations[length].valApprovedDate = new Date();
                if(!vm.serviceProviders.memberAssociations[length].valReviewDate)
                    vm.serviceProviders.memberAssociations[length].valReviewDate = new Date();
                if(vm.serviceProviderAssociations[id].expiryDate)
                    vm.serviceProviders.memberAssociations[length].valExpiryDate = vm.serviceProviderAssociations[id].expiryDate;
            } else {
                console.log('setMemberAssociationsForManagedMembers - setting RPP flag to false: ' + vm.serviceProviders);
                vm.serviceProviders.rppFlag = false;
                vm.serviceProviders.memberAssociations[length].valApproved = false;
                vm.serviceProviders.memberAssociations[length].valApprovedDate = null;
                vm.serviceProviders.memberAssociations[length].valExpiryDate = null;
                vm.serviceProviders.memberAssociations[length].valReviewDate = null;
               // (vm.serviceProviders.memberAssociations[length].valReviewDate).setDate((new
				// Date()).getDate() + 365);
            }

            // saveMemberAssociations();
            if( _.isUndefined(vm.serviceProviderAssociations[id].memberStatus)) {
                vm.serviceProviderAssociations[id].memberStatus = _.find(vm.memberStatuses, {lookupcode: 'PEND'});
                $scope.setStatusDate(vm.serviceProviderAssociations[id].memberAssociation.id);
            }

            save();
        };

        $scope.setIndustryPartnerCapability = function (industryPartnerCapabilities, id, event) {

            console.log('setIndustryPartnerCapability, industry partner capabilities: ' + industryPartnerCapabilities + ', id: ' + id);

        	if ($scope.checkMovingRelocationUSGovt() && $scope.hasNoSelectedIndustrySupplierCapabilities()) {
        		 if (vm.serviceProviderIndustryPartnerCapabilities[id]) {
                     delete vm.serviceProviderIndustryPartnerCapabilities[id];
                 } else {
                     vm.serviceProviderIndustryPartnerCapabilities[id] =  {'capabilityType':industryPartnerCapabilities};
                 }
        	} else {
        	    console.log('setIndustryPartnerCapability - constraint found; clearing industry partner capability, id: ' + id);
        		vm.serviceProviderIndustryPartnerCapabilities[id] = false;
        		// Don't propagate the event to prevent the checkbox to stay selected on multiple clicks
        		$scope.suppressEvent(event);
        		openCapabilityMessage();
        	}
        };

        $scope.suppressEvent = function (event) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        $scope.setIndustrySupplierCapability = function (industrySupplierCapabilities, id, event) {
            console.log('setIndustrySupplierCapability, industry supplier capabilities: ' + industrySupplierCapabilities + ', id: ' + id);

        	if ($scope.checkMovingRelocationUSGovt() && $scope.hasNoSelectedIndustryPartnerCapabilities()) {
        	     console.log('setIndustrySupplierCapability - no selection constraints found');
        		 if (vm.serviceProviderIndustrySupplierCapabilities[id]) {
                     console.log('setIndustrySupplierCapability - delete serviceProviderIndustrySupplierCapabilities, id: ' + id);
                     delete vm.serviceProviderIndustrySupplierCapabilities[id];
                 } else {
                     console.log('setIndustrySupplierCapability - set serviceProviderIndustrySupplierCapabilities, id: ' + id);
                     vm.serviceProviderIndustrySupplierCapabilities[id] =  {'capabilityType':industrySupplierCapabilities };
                 }
        	} else {
        		console.log('setIndustrySupplierCapability - constraint found; clearing industry supplier capability, id: ' + id);
        		vm.serviceProviderIndustrySupplierCapabilities[id] = false;
        		// Don't propagate the event to prevent the checkbox to stay selected on multiple clicks
        		$scope.suppressEvent(event);
        		openCapabilityMessage();
        	}
        };

        $scope.setQualityValidation = function (id) {
            // //console.log('Setting validated for quality id '+id);
            // vm.serviceProviderQualities[id] = {valApproved: true, };
        };

        $scope.setQuality = function (quality, id) {
            if (vm.serviceProviderQualities[id]) {
                // quality exists - remove it
                delete vm.serviceProviderQualities[id];
            }
            else {
                // quality doesn't exist - create it
                vm.serviceProviderQualities[id] = {certificateType: quality,};
            }
        };

        $scope.setCompliance = function (compliance, id) {
            if (vm.serviceProviderCompliance[id]) {
                // quality exists - remove it
                delete vm.serviceProviderCompliance[id];
            }
            else {
                // quality doesn't exist - create it
                vm.serviceProviderCompliance[id] = {certificateType: compliance,};
            }
        };

        $scope.setDestinationService = function (destinationService, id) {
            console.log('Set destination service, id: ' + id + ', destination service: ' + destinationService);
            if (vm.serviceProviderDestinationServices[id]) {
                // Destination service exists - remove it
                delete vm.serviceProviderDestinationServices[id];
            } else {
                // Destination service doesn't exist - create it
                console.log('Setting provider destination service, id: ' + id + ', dspTypeId: ' + destinationService.id);
                vm.serviceProviderDestinationServices[id] = {dspType: destinationService};
            }
        };

        $scope.setUSGovtCapability = function (usGovtCapabilities, id, event) {

        	if ($scope.hasNoSelectedIndustryPartnerAndIndustrySupplierCapabilities()) {
        		if (vm.serviceProviderUSGovCapabilities[id]) {
                    delete vm.serviceProviderUSGovCapabilities[id];
                    vm.serviceProviderUSGovCapabilities.length--;
                } else {
                    vm.serviceProviderUSGovCapabilities[id] =  {'capabilityType':usGovtCapabilities};
                }
        	} else {
        	    console.log('Clearing service provider US government capability, id: ' + id);
        		vm.serviceProviderUSGovCapabilities[id] = false;
        		// Don't propagate the event to prevent the checkbox to stay selected on multiple clicks
        		$scope.suppressEvent(event);
        		openCapabilityMessage();
        	}
        };


        // //////////languages

        $scope.setLanguageCapability = function (languageCapabilities, id) {

            if (vm.serviceProviderLangCapabilities[id]) {
                delete vm.serviceProviderLangCapabilities[id];
            }
            else {
                vm.serviceProviderLangCapabilities[id] =  {'capabilityType':languageCapabilities};
            }
        };

        $scope.getGeoCode = function getGeoCode(event, addr, index) {
            event.preventDefault();

            var addressLine1 = sanitizeAddress(addr.addressline1);
            var addressLine2 = sanitizeAddress(addr.addressline2);

            $http
                .get('/api/geocode?a1=' + addressLine1 + '&a2=' + addressLine2 + '&a3=' + addr.addressline3 + '&c=' + addr.city + '&s=' + addr.state + '&ct=' + addr.country
                ).then(function (response) {
                // //console.log("latitude logitude: "+response.data.lat_lng);
                vm.serviceProviders.serviceproviderAddresses[index].geocode = response.data.lat_lng;
            });
        };

        $scope.showDetails = [];

        vm.showHideMemberDetails = function(index){

            if ($scope.showDetails[index] && $scope.showDetails[index] === true){
                $scope.showDetails[index] = false;
            } else {
                $scope.showDetails[index] = true;
            }
        }

        function sanitizeAddress(addressLine){
            if(addressLine && _.includes(addressLine,'#'))
                addressLine = _.replace(addressLine,'#','');
            return addressLine;
        }

        vm.deleteAddress = function(addToBeDeleted){
            var addresses = vm.serviceProviders.serviceproviderAddresses;
            if(addresses) {
                var addr = _.remove(addresses, function (address, idx) {
                    return address.id == addToBeDeleted.id;
                });
            }
            save();
        };

        vm.deleteContact = function(contactToBeDeleted){
            var contacts = vm.serviceProviders.serviceproviderContacts;
            if(contacts) {
                var cont = _.remove(contacts, function (contact, idx) {
                    return contact.id == contactToBeDeleted.id;
                });
            }
            save();
        };

        vm.removeContact = function(contactToBeDeleted){
            var contacts = vm.serviceProviders.serviceproviderContacts;
            if(contacts) {
                var cont = _.remove(contacts, function (contact, idx) {
                    return contact.id == contactToBeDeleted.id;
                });
            }

            vm.removeContactDialogOpen['' + contactToBeDeleted.id] = true;
            MemberContacts.delete({id: contactToBeDeleted.id},
                    function () {
            	        save();
            	        delete vm.removeContactDialogOpen['' + contactToBeDeleted.id];
            	        // Force refreshing of contacts
            	        vm.transition();
                    });
        };

        if (vm.serviceProviders.id) {
            vm.invoices = InvoiceHeader.getAll({spId: vm.serviceProviders.id});
        }

        /** ** */
        var uploader = $scope.uploader = new FileUploader({
            url: '/api/fileupload?spid=' + entity.id + '&type=profile',
            headers: {
                'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN')
            }

        });

        $scope.uploadCert = [];
        $scope.uploadGuide = [];
        $scope.uploadMemberShip = [];
        $scope.established = [];
        $scope.profileImage = [];
        $scope.logoImage = [];
        $scope.businessImage = [];
        $scope.advImage = [];
        $scope.endoImage = [];
        $scope.uploadCtqual = [];

        $scope.getFileRef = function () {
            try {
                console.log('Processing service provider files');
                if (vm.serviceProviders.serviceproviderFiles) {
                    for (var i = 0; i < vm.serviceProviders.serviceproviderFiles.length; i++) {
                        if(vm.serviceProviders.serviceproviderFiles[i]
                            && vm.serviceProviders.serviceproviderFiles[i].active === true){

                            var tkns = _.split(vm.serviceProviders.serviceproviderFiles[i].imageName, '_');
                            var spid = tkns[0];
                            var itemId = tkns[1];
                            var itemType = vm.serviceProviders.serviceproviderFiles[i].image_type;
                            var path = _.replace(vm.serviceProviders.serviceproviderFiles[i].imagePath,'/apps','');
                            console.log('Processing service provider file, index: ' + i + ', itemType: ' + itemType + ', itemId: ' + itemId + ', path: ' + path);
                            if (itemType === 'certificate') {
                                $scope.uploadCert[itemId] = path;
                                console.log('Processed service provider file, index: ' + i + ', itemType: ' + itemType + ', itemId: ' + itemId + ', path: ' + path + ', upload cert: ' + $scope.uploadCert[itemId]);
                            }
                            if (itemType === 'guide')
                                $scope.uploadGuide[itemId] = path;
                            if (itemType === 'membership')
                                $scope.uploadMemberShip[itemId] = path;
                            if (itemType === 'established')
                                $scope.established[0] = path;
                            if (itemType === 'profile')
                                $scope.profileImage[0] = path;
                            if (itemType === 'logo')
                                $scope.logoImage[0] = path;
                            if (itemType === 'business_reg'){
                            	$scope.businessImage[0] = path;
                            	$scope.bussinessImageUploadedBy = vm.serviceProviders.serviceproviderFiles[i].createdby;
                            	$scope.bussinessImageUploadedDate = vm.serviceProviders.serviceproviderFiles[i].createddate;
                            }
                            if (itemType === 'Advertising')
                                $scope.advImage[0] = path;
                            if (itemType === 'Endorsment')
                                $scope.endoImage[0] = path;
                            if (itemType === 'qual')
                                $scope.uploadCtqual[itemId] = path;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        $scope.getFileRef();

        vm.deleteDoc = function(index,itemType, path){

            var delId ;
            if (vm.serviceProviders.serviceproviderFiles) {
                for (var i = 0; i < vm.serviceProviders.serviceproviderFiles.length; i++) {
                    var filePath = '/apps'+path;
                    if (vm.serviceProviders.serviceproviderFiles[i]
                        && filePath  === vm.serviceProviders.serviceproviderFiles[i].imagePath
                        && vm.serviceProviders.serviceproviderFiles[i].active === true
                    ) {
                            // console.log(vm.serviceProviders.serviceproviderFiles[i]);

                            delId = vm.serviceProviders.serviceproviderFiles[i].id;
                            // delete
							// vm.serviceProviders.serviceproviderFiles[i];
                            vm.serviceProviders.serviceproviderFiles.splice(i,1);

                            // console.log(vm.serviceProviders.serviceproviderFiles[i]);

                            if (itemType === 'profile')
                                delete $scope.profileImage[index];
                            if (itemType === 'logo')
                                delete $scope.logoImage[index];
                            if (itemType === 'business_reg')
                                delete $scope.businessImage[index];
                            if (itemType === 'Advertising')
                                delete $scope.advImage[index];
                            if (itemType === 'Endorsment')
                                delete $scope.endoImage[index];
                            if (itemType === 'established')
                                delete $scope.established[index];
                            if (itemType === 'membership')
                                delete $scope.uploadMemberShip[index];
                            if (itemType === 'certificate') {
                                console.log('Deleting certificate, index: ' + index);
                                delete $scope.uploadCert[index];
                            }
                            if (itemType === 'guide')
                                delete $scope.uploadGuide[index];
                            if (itemType === 'qual')
                                delete $scope.ctqual[index];

                            break;
                    }
                }
            }

            $http.delete('/api/deleteFile/'+delId).then( function(response){
                // Transform service providers response to align data with the UI-expected format.
                // TODO: Move transformation method to a common module that can be used by both ServiceProviders service
                // and this controller.
                var transformedServiceProviders = transformServiceProvidersAfterFileDelete(response.data);
                onSaveSuccess(transformedServiceProviders);
            });
        };

        $scope.$on('$stateChangeStart', function (event) {
            // console.log('changing location ....' + event);
            if ($scope.editForm.$dirty) {
                var answer = confirm('Have you saved changes? ' +
                    'Please click Ok to leave this page, any unsaved changes will be lost. ' +
                    'Click Cancel to stay on the page.');
                if (!answer) {
                    event.preventDefault();
                }
            }
        }
       );

     // payment

        $scope.uploadCertNow = [];
        $scope.uploadGuideNow = [];
        $scope.uploadMemberShipNow = [];
        $scope.establishedNow = [];
        $scope.profileImageNow = [];
        $scope.logoImageNow = [];
        $scope.businessImageNow = [];
        $scope.advImageNow = [];
        $scope.endoImageNow = [];
        $scope.ctQualNow = [];

        $scope.uploadCertError = [];
        $scope.uploadGuideError = [];
        $scope.uploadMemberShipError = [];

        $scope.saveChanges = false;

     // FILTERS

        uploader.filters.push({
            name: 'profileFilter',
            fn: function(item /* {File|FileLikeObject} */, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                if('|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) === -1){
                	$scope.profileImageError = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";

                }
                return '|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) !== -1;
            }
        });

        uploader.filters.push({
            name: 'logoFilter',
            fn: function(item /* {File|FileLikeObject} */, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                if('|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) === -1){
                	$scope.logoImageError = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
                }
                return '|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) !== -1;
            }
        });

        uploader.filters.push({
            name: 'businessFilter',
            fn: function(item /* {File|FileLikeObject} */, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                if('|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) === -1){
                	$scope.businessImageError = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";
                }
                return '|jpg|png|jpeg|bmp|gif|pdf|tif|bmp'.indexOf(type) !== -1;
            }
        });

        // CALLBACKS

        uploader.onWhenAddingFileFailed = function (item /* {File|FileLikeObject} */, filter, options) {
            console.info('onWhenAddingFileFailed', item, filter, options);
        };

        uploader.onAfterAddingFile = function (fileItem) {

            console.info('onAfterAddingFile', fileItem);

            $scope.showSpinner = true;
            if (fileItem.type == 'uploadCert')
                $scope.uploadCertNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'uploadGuide')
                $scope.uploadGuideNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'uploadMemberShip')
                $scope.uploadMemberShipNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'established')
                $scope.establishedNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'profileImage')
                $scope.profileImageNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'logoImage')
                $scope.logoImageNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'businessImage')
                $scope.businessImageNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'advImage')
                $scope.advImageNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'endoImage')
                $scope.endoImageNow[fileItem.id] = fileItem.file.name;
            if (fileItem.type == 'qual')
                $scope.ctQualNow[fileItem.id] = fileItem.file.name;

            /*
			 * if($scope.saveChanges) save();
			 */
            if (uploader.queue) {
                uploader.uploadAll();
            }
        };

        uploader.onAfterAddingAll = function (addedFileItems) {
            console.info('onAfterAddingAll', addedFileItems);
        };

        uploader.onBeforeUploadItem = function (item) {
            // item.formData.push({serviceproviderData:
			// angular.toJson(vm.serviceProviders)});
            console.info('onBeforeUploadItem', item);
        };

        uploader.onProgressItem = function (fileItem, progress) {
            console.info('onProgressItem', fileItem, progress);
        };

        uploader.onProgressAll = function (progress) {
            console.info('onProgressAll', progress);
        };

        uploader.onSuccessItem = function (fileItem, response, status, headers) {
            console.info('onSuccessItem', fileItem, response, status, headers);

            // console.log('After upload :: '+JSON.stringify(response));

            response.serviceprovider = vm.serviceProviders.id;

            if(vm.serviceProviders.serviceproviderFiles) {
                // delete response.serviceprovider;
                // response.serviceprovider = vm.serviceProviders.id;
                vm.serviceProviders.serviceproviderFiles.push(response);

                console.log("Adding file to SP:  ");
                // console.log(vm.serviceProviders);

            }
            else{
                // delete response.serviceprovider;
                vm.serviceProviders.serviceproviderFiles = [];
                // response.serviceprovider = vm.serviceProviders.id;
                vm.serviceProviders.serviceproviderFiles.push(response);
            }


           // vm.serviceProviders = response.data;

            if (fileItem.type == 'uploadCert'){
                $scope.uploadCertError[fileItem.id] = "";
                $scope.uploadCertNow[fileItem.id] = "";
            }

            if (fileItem.type == 'uploadGuide'){
                $scope.uploadGuideError[fileItem.id] = "";
                $scope.uploadGuideNow[fileItem.id] = "";
            }

            if (fileItem.type == 'uploadMemberShip'){
                $scope.uploadMemberShipError[fileItem.id] = "";
                $scope.uploadMemberShipNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'established'){
                $scope.establishedError = "";
                $scope.establishedNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'profileImage'){
                $scope.profileImageError = "";
                $scope.profileImageNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'logoImage'){
                $scope.logoImageError = "";
                $scope.logoImageNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'businessImage'){
                $scope.businessImageError = "";
                $scope.businessImageNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'advImage'){
                $scope.advImageError = "";
                $scope.advImageNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'endoImage'){
                $scope.endoImageError = "";
                $scope.endoImageNow[fileItem.id] =  "";
            }

            if (fileItem.type == 'qual'){
                $scope.ctQualError = "";
                $scope.ctQualNow[fileItem.id] =  "";
            }

           // setupFormData();

           // save();

        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {

            var errorMessage = "Error uploading file, Files must be 3 megabyte or less. Only PDF and image files are permitted. Please contact support@mobilityex.com if you require assistance";

            if (fileItem.type == 'uploadCert')
                $scope.uploadCertError[fileItem.id] = errorMessage;

            if (fileItem.type == 'uploadGuide')
                $scope.uploadGuideError[fileItem.id] = errorMessage;

            if (fileItem.type == 'uploadMemberShip')
                $scope.uploadMemberShipError[fileItem.id] = errorMessage;

            if (fileItem.type == 'established')
                $scope.establishedError = errorMessage;

            if (fileItem.type == 'profileImage')
                $scope.profileImageError = errorMessage;

            if (fileItem.type == 'logoImage')
                $scope.logoImageError = errorMessage;

            if (fileItem.type == 'businessImage')
                $scope.businessImageError = errorMessage;

            if (fileItem.type == 'Advertising' || fileItem.type == 'advImage')
                $scope.advImageError = errorMessage;

            if (fileItem.type == 'Endorsment')
                $scope.endoImageError = errorMessage;

            if (fileItem.type == 'qual')
                $scope.ctQualError = errorMessage;

        };

        uploader.onCancelItem = function (fileItem, response, status, headers) {
            console.info('onCancelItem', fileItem, response, status, headers);
        };

        uploader.onCompleteItem = function (fileItem, response, status, headers) {
            console.info('onCompleteItem', fileItem, response, status, headers);
        };

        uploader.onCompleteAll = function () {
            console.info('onCompleteAll');
            $scope.showSpinner = false;
            // $window.location.reload();
            $scope.saveChanges = false;
            $scope.getFileRef();
            getValidationCheclkist(vm.serviceProviders.id);
        };

        /** **** */

        /**
		 * Checks expiring condition.
		 *
		 * @param dateString -
		 *            Date in ISO string format.
		 * @param daysLeft -
		 *            Number of days left to expiration when this date is
		 *            counted as expiring soon.
		 */

         $scope.isExpiringSoon = function (date, daysLeft) {
        	if (date){
        	    if (typeof date.getTime === 'function') {
        		    return Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)) < daysLeft;
        		} else {
        		    // Date comes in as a string, rather than a date object. Convert the string to a date before
        		    // evaulating expiration.
        		    var expirationDate = new Date(date);
        		    return Math.ceil((expirationDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24)) < daysLeft;
        		}
        	} else {
        		return false;
        	}
        };

        // the button will always be
        vm.canRequestValidation = function () {
            if (vm.serviceProviders.mxstatus && vm.serviceProviders.mxstatus.lookupcode === '2') {
                return true;
            }
            else {
                return false;
            }
        };

        $scope.validationRequestBtnLabel = "Validate My Listing";
        // setValidationBtnLabel();
        vm.getValidationBtnLabel = function () {
            if (vm.serviceProviders.mxstatus != null && vm.serviceProviders.mxstatus.lookupcode == '3') {
                return "Validation Underway";
            } else {
                return "Validate My Listing";
            }
        };

        vm.getValidationRequestUnderwayStatus = function () {
            return vm.serviceProviders.mxstatus != null &&
                (vm.serviceProviders.mxstatus.lookupcode === '3' || vm.serviceProviders.mxstatus.lookupcode === '4'
                    || vm.serviceProviders.mxstatus.lookupcode === '5');
        };

        $scope.requestValidation = function () {
            if(vm.getFees()===0){
                for (var i = 0; i < vm.mxStatus.length; i++) {
                    if (vm.mxStatus[i].lookupcode === '3') {
                        vm.serviceProviders.mxstatus = vm.mxStatus[i];
                        break;
                    }
                }
                save();
            }else{
                $state.go('payment', {id:vm.serviceProviders.id, sp:vm.serviceProviders}, {reload: false});
            }
        };


        vm.getFees = function(){
            var fees = 360;
            if(vm.serviceProviders.memberAssociations){
                for(var i=0; i < vm.serviceProviders.memberAssociations.length; i++){
                    var memberType = vm.serviceProviders.memberAssociations[i].memberType;
                    if(memberType !== null){
                        var mFees = parseInt(memberType.flex4);
                        if(mFees < fees)
                            fees = mFees;
                    }
                }
            }
            return fees;
        };

        vm.auditLogsActedOn = [];

        vm.acceptAll = function(){
            if(vm.revokeAllVal)  vm.revokeAllVal = false;
            for(var i=0; i< vm.auditLogs.length; i++){
                vm.auditLogs[i].lastAction = 'ACC';
                vm.auditLogsActedOn.push(vm.auditLogs[i]);
                AuditLog.revokeacceptChange(vm.auditLogs[i]);
            }
        }

        vm.revokeAll = function(){
            if(vm.acceptAllVal)  vm.acceptAllVal = false;
            for(var i=0; i< vm.auditLogs.length; i++){
                vm.auditLogs[i].lastAction = 'REV';
                vm.auditLogsActedOn.push(vm.auditLogs[i]);
                AuditLog.revokeacceptChange(vm.auditLogs[i]);
            }
        }

        vm.revokeacceptChange = function(auditLog, lastAction){
            auditLog.lastAction =lastAction;
            auditLog.recDisplay = false;
            vm.auditLogsActedOn.push(auditLog);
            AuditLog.revokeacceptChange(auditLog);
        };

        $scope.showAll = 'false';

        vm.getPendingAuditData = function(){
            if (vm.serviceProviders.id) {
                vm.auditLogs = AuditLog.getPendingSPData({spid:vm.serviceProviders.id});
            }
            $scope.showAll = 'false';
        };

        vm.getAllAuditData = function(){
            vm.auditLogs = AuditLog.getSPData({spid:vm.serviceProviders.id});
            $scope.showAll = 'true';
        };

        vm.getPendingAuditData();

        $scope.actor;
        $scope.actions;
        $http.get
        (
            'api/usersAnalystlist',
            {}
        )
            .then
            (
                function (response) {
                    $scope.actors =
                        response.data
                            .filter
                            (
                                function (user) {
                                    return (user.activated && user.authorities.indexOf("ROLE_ANALYST") != -1);

                                }
                            )
                            .map
                            (
                                function (user) {
                                    user.name = user.firstName + " " + user.lastName;
                                    return user;

                                }
                            )
                    ;
                }
            )
        ;

        // get actions

        $http.get
        (
            'api/lookups/lookuptypes/20',
            {}
        )
            .then
            (
                function (response) {
                    $scope.actions = response.data;

                    for (var i = 0; i < $scope.actions.length; i++) {

                        if (vm.customerNotes && vm.customerNotes.action != null && $scope.actions[i].id == vm.customerNotes.action.id) {
                            vm.customerNotes.action = $scope.actions[i];
                            break;

                        }

                    }

                }
            )
        ;
    }
})();
