(function() {
	//'use strict';

	angular.module('mobilityexchangeApp').controller(
			'MemberContactsController', MemberContactsController);

	MemberContactsController.$inject = [ '$rootScope', '$scope', '$state',
			'MemberContacts', 'MemberContactsSearch', 'ParseLinks',
			'AlertService', 'pagingParams', 'paginationConstants', 'Principal',
			'$log', '$stateParams' ,'Lookups', '$http','$window', '$timeout'];

	function MemberContactsController($rootScope, $scope, $state,
			MemberContacts, MemberContactsSearch, ParseLinks, AlertService,
			pagingParams, paginationConstants, Principal, $log, $stateParams, Lookups, $http, $window, $timeout) {
		var vm = this;
		$rootScope.image_path = "";
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.transitionOnKeyUp = transitionOnKeyUp;
		vm.search = search;
		vm.loadAll = loadAll;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;
		vm.mid = $stateParams.mid;
		vm.firstname = $stateParams.firstname;
		vm.lastname = $stateParams.lastname;
		vm.title = $stateParams.title;
		vm.company = $stateParams.company;
		vm.mxstatus = $stateParams.mxstatus;
		vm.city = $stateParams.city;
		vm.state = $stateParams.state;
		vm.country = $stateParams.country;
		$rootScope.showSearchBox = true;
		$rootScope.mainLogoLink = "#";
		vm.emailSubscriptions = Lookups.getByLookupType({id: 65});
		vm.showCommPref = false;
		vm.commPreSwitchdata =[];
		loadAll();
		
		$scope.ifUserImpersonator = function() {
			return Principal.hasAnyAuthority(['IMPERSONATOR']);
		};

		vm.impersonate = function(email) {
			$http.get('api/login/impersonate?username=' + email).then(
					function(res) {
						$state.go('home',{'ref':'1'}, {reload: true});
						$timeout(function(){
			                $window.location.reload();
			            },1000);
					});
		}

		vm.displayInvoiceButton = function(s) {
			return true;
		};

		function loadAll() {
			if (pagingParams.search) {
				MemberContactsSearch.query({
					query : pagingParams.search,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort(),
					mid : vm.mid ? vm.mid : $stateParams.mid,
					firstname : vm.firstname ? vm.firstname
							: $stateParams.firstname,
					lastname : vm.lastname ? vm.lastname
							: $stateParams.lastname,
					title : vm.title ? vm.title : $stateParams.title,
					company : vm.company ? vm.company : $stateParams.company,
					mxstatus : vm.mxstatus ? vm.mxstatus : $stateParams.mxstatus,
					city : vm.city ? vm.city : $stateParams.city,
					email : vm.email ? vm.email : $stateParams.email,
					country : vm.country ? vm.country : $stateParams.country
				}, onSuccess, onError);
			} else {
				MemberContacts.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			}
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'lastname') {
					result.push('lastname');
				}
				return result;
			}

			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.MemberContacts = data;
				vm.page = pagingParams.page;

			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				mid : vm.mid ? vm.mid : $stateParams.mid,
				firstname : vm.firstname ? vm.firstname
						: $stateParams.firstname,
				lastname : vm.lastname ? vm.lastname : $stateParams.lastname,
				title : vm.title ? vm.title : $stateParams.title,
				company : vm.company ? vm.company : $stateParams.company,
				mxstatus : vm.mxstatus ? vm.mxstatus : $stateParams.mxstatus,
				city : vm.city ? vm.city : $stateParams.city,
				email : vm.email ? vm.email : $stateParams.email,
				country : vm.country ? vm.country : $stateParams.country
			});
		}

		function search(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'lastname';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'lastname';
			vm.reverse = true;
			vm.currentSearch = null;
			vm.transition();
		}
		
		$scope.goToOnKeyUp = function (e, searchQuery) {
		     if(event.keyCode === 13){
		    	vm.transitionOnKeyUp(searchQuery);
		    }
		}
		
		function transitionOnKeyUp(searchQuery) {
			searchQuery = '-';
			if (!searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'lastname';
			vm.reverse = false;
			vm.currentSearch = searchQuery;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch,
				mid : vm.mid,
				firstname : vm.firstname,
				lastname : vm.lastname,
				title : vm.title,
				company : vm.company,
				mxstatus : vm.mxstatus,
				city : vm.city,
				email : vm.email,
				country : vm.country
			});
		}
		
		
		
	}
})();